import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";

interface FormulaInputProps {
    id?: string;
    value: string | number;
    onChange: (params: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isBottom?: boolean;
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    placeholder?: string | undefined;
}

const CutLengthsInput = styled.input`
    ::placeholder {
        color: #C2C2C2; 
    }
    :focus-within {
        outline: none;
        border: 1px solid #3b97b1 !important;
    }
    padding-left: 6px;
`;

const FormulaInput: FC<FormulaInputProps> = ({
    id,
    value,
    onChange,
    isBottom = false,
    className = "",
    handleKeyDown,
    onFocus,
    placeholder
}) => {
    return (
        <div className={`${isBottom ? "" : "border-b border-[#E2E2E2]"} h-14`}>
            <div className="flex flex-row h-full items-center justify-center p-2">
                <p className="text-[#222222] font-normal leading mb-0 mr-2">
                    {id && id.includes("TrimHorizontal") ? "FW +" : "FL +"}
                </p>
                <CutLengthsInput
                    id={id}
                    name={id}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    value={value}
                    className="mr-2 rounded-[8px] items-center border max-w-[72px] px-2 py-[10px] h-9 font-normal border-[#AAAAAA]"
                    step={0.001}
                    type="number"
                    onFocus={onFocus}
                    placeholder={placeholder}
                />
                <p className="text-[#222222] font-normal leading">in.</p>
            </div>
        </div>
    );
};

export default FormulaInput;
