import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import { ClientPricingAction, PricingType } from "../types";
import {
    DraperyItemDetailsFields,
    DraperyItemDetailsFieldsAction,
    PricingGroupItem,
    PricingSimpleItem,
} from "../../../redux/modules/my-shop/drapery-item-details-fields-reducer";
import {
    FormInput,
    SectionTitle,
    TooltipContainer,
    TooltipPopup,
    TooltipText,
} from "../styles";
import React, { ChangeEvent, Dispatch, FC, useEffect, useState } from "react";
import { checkIfGroupItem, validatePricingInput } from "../helpers";
import { draperyPricingTypes, 
    // draperyWidthRoundUpTypes
 } from "../constants";

import { Checkbox } from "@ramble/ramble-ui";
import PricingGroupGrid, { PopUpMessageContainer } from "../pricing-group-grid";
import SelectList from "../../UI/select-list";
import TooltipSpecialtyFabricHandlingChecked from "../../../assets/images/specialty-fabric-handling-checked.png";
// import AddButtonE from "../../UI/buttons/add-button";
// import styled from "styled-components";
import PleatModal from "../pleat-modal/pleat-modal";
import { FormFields } from "../../../redux/modules/my-shop/form-fields-reducer";

interface PricingProps {
    browser: BrowserInfo | BotInfo | NodeInfo | null;
    draperyItemDetailsFields: DraperyItemDetailsFields;
    dispatch: Dispatch<DraperyItemDetailsFieldsAction>;
    itemId: number | null;
    activeItemClassId: number | null;
    formFields: FormFields;
    highLightInput?: string[];
    removeErrorsById?(id: string): void;
    errorFields?: any[];
    firstError?: any;
    hideErrorMessagePopUp?(e: any): void;
    showErrorMessagePopUp?(e: any): void;
}

// const AddButton = styled(AddButtonE)`
//     margin: 10px auto 0px 10px; 
//     font-size: 15px;
//     border-radius: 8px;
//     z-index: 10; 
// `;


const Pricing: FC<PricingProps> = ({
    browser,
    draperyItemDetailsFields,
    dispatch,
    itemId,
    formFields,
    highLightInput,
    activeItemClassId,
    removeErrorsById,
    errorFields,
    firstError,
    hideErrorMessagePopUp,
    showErrorMessagePopUp,
}) => {
    const {
        pricingTypeName,
        isClientPricingActionOn,
        clientPricingAction,
        clientPricingValue,
        // isPartialWidthRoundingOn,
        // partialWidthRoundUpTypeName,
        pleatTypesWithPricing,
        liningAndInterliningWithPricing,
        valanceAndReturns,
        // cafeCurtainsWidthInches,
        // cafeCurtainsWidthPricing,
        extraLargePanelsLengthInches,
        extraLargePanelsLengthPricing,
        extraLargePanelsWidthInches,
        extraLargePanelsWidthPricing,
        // draperyPinningWidthPricing,
        specialityFabricsMarkUpPercentage,
        embellishmentsWithPricing,
        // addOnsWithPricing,
    } = draperyItemDetailsFields;
    const [isPleatModalOpen, setIsPleatModalOpen] = useState(false);

    const autoCompleteOffSetting =
        browser && browser.name === "chrome" ? "new-password" : "off";

    const partialWidthRoundUpGridHeaders = [
        {
            title: "STYLE",
        },
        {
            title: `Base ${pricingTypeName} price`.toUpperCase(),
            subTitle: "unlined, per sq ft",
        },
    ];

    const liningAndInterliningHeaders = [
        {
            title: "LINING & INTERLINING",
        },
        {
            title: "ADDITIONAL LABOR PRICE",
            subTitle: "per sq ft",
        },
    ];

    const valanceAndReturnsHeaders = [
        {
            title: "VALANCE & RETURNS",
        },
        {
            title: "PRICE",
            subTitle: "",
        },
    ];

    // const addOnsHeaders = [
    //     {
    //         title: "TYPE",
    //     },
    //     {
    //         title: `PRICE`,
    //         subTitle: "per width",
    //     },
    // ];

    const embellishmentsPricingHeaders = [
        {
            title: "TYPE",
        },
        {
            title: "PRICE",
            subTitle: "per applied foot",
        },
    ];
    const formInputStyleClass = "px-1.5 py-2 ml-2 max-w-[80px]";

    const [clientType, setClientType] = useState<string>("");

    const getClientType = () => {
        if (!pricingTypeName || !pricingTypeName.length) {
            return "";
        }
        return pricingTypeName === PricingType.Retail
            ? PricingType.Trade
            : PricingType.Retail;
    };

    const handlePricingTypeChange = (
        value: string | number,
        name: string
    ): void => {
        const targetValue = value as string | number | boolean;
        let updates = {
            [name]: targetValue,
        };
        const selectedItem = draperyPricingTypes.find(
            (pt) => pt.key === targetValue
        );
        const pricingTypeName = selectedItem ? selectedItem.label : "";

        updates = {
            ...updates,
            pricingTypeName,
        };

        if (pricingTypeName && pricingTypeName.length) {
            const clientPricingAction =
                pricingTypeName === PricingType.Retail
                    ? ClientPricingAction.Discount
                    : ClientPricingAction.MarkUp;
            updates.clientPricingAction = clientPricingAction;
        } else {
            setClientType("");
            updates.clientPricingAction = "";
            updates.clientPricingValue = "";
            updates.isClientPricingActionOn = true;
        }

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updates,
        });
        
        if (removeErrorsById) {
            removeErrorsById(name)
        }
    };

    // const handlePartialWidthRoundUpChange = (
    //     value: string | number,
    //     name: string
    // ): void => {
    //     const targetValue = value as string | number | boolean;
    //     let updates = {
    //         [name]: targetValue,
    //     };

    //     const selectedItem = draperyWidthRoundUpTypes.find(
    //         (pt) => pt.key === targetValue
    //     );
    //     const selectedValue = selectedItem ? selectedItem.label : "";

    //     updates = {
    //         ...updates,
    //         partialWidthRoundUpTypeName: selectedValue,
    //     };

    //     dispatch({
    //         type: "updateFormFields",
    //         formFieldUpdates: updates,
    //     });
    // };

    const toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const fieldName = e.currentTarget.name;
        const value = !draperyItemDetailsFields[fieldName];

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: {
                [fieldName]: value,
            },
        });
    };

    const handlePricingInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        keyParam?: string,
        type: string = "dollarAmount"
    ) => {
        let value = e.target.value;

        const numericValue = parseFloat(value);

        if (isNaN(numericValue) || (value.length === 1 && value[0] === '.')) {
            value = '';
        }

        if (value === '00') {
            value = '';
        }

        if (value.startsWith('0') && value.length > 1) {
            value = `0.${value.slice(1)}`;
        }

        value = value.replace(/[^\d.]/g, '');

        value = value.replace(/\.{2,}/g, '.');

        const firstDotIndex = value.indexOf('.');
        if (firstDotIndex !== -1) {
            value = `${value.slice(0, firstDotIndex + 1)}${value.slice(firstDotIndex + 1).replace(/\./g, '')}`;
        }

        const decimalParts = value.split('.');
        
        // extraLargePanelsLengthInches
        // extraLargePanelsWidthInches
        if (decimalParts.length > 1) {
            if (keyParam === 'extraLargePanelsWidthPricing' || keyParam === 'extraLargePanelsLengthPricing' || keyParam === 'clientPricingValue') {
                value = `${decimalParts[0].slice(0, 3)}.${decimalParts[1].slice(0, 2)}`;
            }else if (keyParam === 'extraLargePanelsLengthInches' || keyParam === 'extraLargePanelsWidthInches') {
                value = `${decimalParts[0].slice(0, 3)}.${decimalParts[1].slice(0, 3)}`;
            }
            else{
                value = `${decimalParts[0].slice(0, 2)}.${decimalParts[1].slice(0, 2)}`;
            }
        } else {
            if (keyParam === 'extraLargePanelsWidthPricing' || keyParam === 'extraLargePanelsLengthPricing' || keyParam === 'clientPricingValue') {
                value = value.slice(0, 3);
            }else if (keyParam === 'extraLargePanelsLengthInches' || keyParam === 'extraLargePanelsWidthInches') {
                value = value.slice(0, 3);
            }
            else{
                value = value.slice(0, 2);
            }
        }

        const keyboardEvent = e.nativeEvent as any;
        const isBackspaceAction = keyboardEvent.inputType === 'deleteContentForward';

        if (isBackspaceAction && value === '0' && numericValue === 0) {
            value = '';
        }

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: {
                [keyParam || type]: value,
            },
        });
        if (removeErrorsById) {
            removeErrorsById(e.currentTarget.id)
        }
    };

    const handlePricingGridsInputsChange = ({
        e,
        gridName,
        type = "dollarAmount",
        keyParam,
    }: {
        e: ChangeEvent<HTMLInputElement>;
        gridName: string;
        type?: string;
        keyParam?: string;
    }) => {
        const inputId = e.currentTarget.id;
        const updates = validatePricingInput({
            event: e,
            keyParam,
            type,
        });
        if (inputId === 'draperyPinning') {
            const keyParam = "draperyPinningWidthPricing";
            const type = "text";
            handlePricingInputChange(e, keyParam, type);
        }
        let pricingInputGrid = draperyItemDetailsFields[gridName].map(
            (item: PricingSimpleItem | PricingGroupItem) => {
                let itemTyped;
                const isGroupItem = checkIfGroupItem(item);
                if (isGroupItem) {
                    itemTyped = item as PricingGroupItem;
                    const { items } = itemTyped.group;
                    const itemsUpdated = items.map((i) => {
                        const { id } = i;
                        if (id === inputId) {
                            let roundedValue = parseFloat(updates[inputId]);

                            if (isNaN(roundedValue)) {
                                roundedValue = 0;
                            }

                            if (updates[inputId].startsWith('0') && updates[inputId].length > 1 && !updates[inputId].includes('.')) {
                                updates[inputId] = '0';
                            }

                            return { ...i, price: updates[inputId] };
                        }
                        return i;
                    });
                    itemTyped.group.items = itemsUpdated;
                    return itemTyped;
                }

                itemTyped = item as PricingSimpleItem;
                const { id } = itemTyped;
                if (id === inputId) {
                    let roundedValue = parseFloat(updates[inputId]);

                    if (isNaN(roundedValue)) {
                        roundedValue = 0;
                    }

                    if (updates[inputId].startsWith('0') && updates[inputId].length > 1 && !updates[inputId].includes('.')) {
                        updates[inputId] = '0';
                    }

                    return { ...itemTyped, price: updates[inputId] };
                }
                return itemTyped;
            }
        );

        const keyboardEvent = e.nativeEvent as any;
        const isDeleteAction = keyboardEvent.inputType === 'deleteContentBackward';
        const isBackspaceAction = keyboardEvent.inputType === 'deleteContentForward';

        if ((isDeleteAction || isBackspaceAction) && updates[inputId] === '0' && updates[inputId] !== e.currentTarget.value) {
            updates[inputId] = '';
        }

        const decimalParts = updates[inputId].split('.');
        if (decimalParts.length > 1) {
            updates[inputId] = `${decimalParts[0].slice(0, 3)}.${decimalParts[1].slice(0, 2)}`;
        } else {
            updates[inputId] = updates[inputId].slice(0, 3);
        }

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: {
                [gridName]: pricingInputGrid,
            },
        });

        if (removeErrorsById) {
            removeErrorsById(inputId)
        }
    };

    useEffect(() => {
        const clientTypeVal = getClientType();
        setClientType(clientTypeVal);
    }, [pricingTypeName]);

    // const openPleatModal = (event: { preventDefault: () => void; }) => {
    //     event.preventDefault();
    //     setIsPleatModalOpen(true);
    // };

    const closePleatModal = () => {
        setIsPleatModalOpen(false);
    };

    // const updatedPricingRows = [
    //     {
    //         id: 'draperyPinning',
    //         name: 'Drapery Pinning',
    //         price: draperyPinningWidthPricing,
    //     },
    //     ...addOnsWithPricing ? addOnsWithPricing : []
    // ];

    return (
        <div className="flex flex-wrap w-full items-start">
            <div className="lg:w-3/4 mb-6 pr-6 mx-auto">
                <div className="flex flex-col mb-2">
                    <div style={{marginTop:'5px'}}>
                        <SectionTitle>SETUP</SectionTitle>
                        <div className="flex flex-row items-center relative">
                            These are
                            <div className="inline-flex relative">
                                <PopUpMessageContainer className={`${firstError && firstError.show && firstError.name === 'pricingTypeId' && 'active'}`}>
                                    <span>{'Please fill out this field.'}</span>
                                </PopUpMessageContainer>
                                <SelectList
                                    name="pricingTypeId"
                                    flexDirection="column"
                                    required={false}
                                    noAddButton
                                    valueSelect={pricingTypeName}
                                    handleClickSelect={handlePricingTypeChange}
                                    className={`max-w-[150px] ml-2 mr-2 ${errorFields && errorFields.length > 0 && errorFields.some(i => i.name === 'pricingTypeId') ? 'error-pleatType' : ''}`}
                                    selectList={draperyPricingTypes}
                                    customValue
                                    hideErrorMessagePopUp={hideErrorMessagePopUp}
                                    showErrorMessagePopUp={showErrorMessagePopUp}
                                />
                            </div>
                            <span className="ml-2">prices</span>
                        </div>
                        {pricingTypeName && !!pricingTypeName.length && (
                            <div className="mt-4">
                                <Checkbox
                                    name="isClientPricingActionOn"
                                    checked={isClientPricingActionOn}
                                    onChange={toggleCheckbox}
                                />
                                <span>{clientPricingAction} these prices</span>
                                &nbsp;
                                <div className="inline-flex relative">
                                    <PopUpMessageContainer className={`${firstError && firstError.show && firstError.name === 'clientPricingValue' && 'active'}`}>
                                        <span>{'Please fill out this field.'}</span>
                                    </PopUpMessageContainer>
                                    <FormInput
                                        id="clientPricingValue"
                                        name="Client Pricing Value"
                                        className={`${formInputStyleClass} ${errorFields && errorFields.length > 0 && errorFields.some(i => i.name === 'clientPricingValue') ? 'error-pleatType' : ''}`}
                                        type="text"
                                        value={clientPricingValue}
                                        placeholder="20.00"
                                        onChange={(e) =>
                                            handlePricingInputChange(
                                                e,
                                                "clientPricingValue",
                                                "percentage"
                                            )
                                        }
                                        onFocus={showErrorMessagePopUp}
                                        onBlur={hideErrorMessagePopUp}
                                        uiReportOnBlur={false}
                                        disabled={!isClientPricingActionOn}
                                        required={false}
                                        uiDisabled={false}
                                        autoComplete={autoCompleteOffSetting}
                                        list="autocompleteOff"
                                    />
                                </div>
                                <span className="ml-2">{`% for my ${clientType}`} clients</span>
                            </div>
                        )}
                    </div>
                    <div className="mt-5">
                        <div className="flex flex-col">
                            <div className="mt-4">
                                <PricingGroupGrid
                                    headers={partialWidthRoundUpGridHeaders}
                                    rows={pleatTypesWithPricing}
                                    activeItemClassId={activeItemClassId}
                                    handleInputChange={(e) =>
                                        handlePricingGridsInputsChange({
                                            e,
                                            gridName: "pleatTypesWithPricing",
                                        })
                                    }
                                    formFields={formFields}
                                    draperyItemDetailsFields={draperyItemDetailsFields}
                                    itemId={itemId}
                                    highLightInput={highLightInput}
                                    errorFields={errorFields}
                                    firstError={firstError}
                                    inputRequired={false}
                                    hideErrorMessagePopUp={hideErrorMessagePopUp}
                                    showErrorMessagePopUp={showErrorMessagePopUp}
                                />
                            </div>
                            <div className="mt-4">
                                <PricingGroupGrid
                                    headers={liningAndInterliningHeaders}
                                    rows={liningAndInterliningWithPricing}
                                    activeItemClassId={activeItemClassId}
                                    handleInputChange={(e) =>
                                        handlePricingGridsInputsChange({
                                            e,
                                            gridName:
                                                "liningAndInterliningWithPricing",
                                        })
                                    }
                                    errorFields={errorFields}
                                    firstError={firstError}
                                    inputRequired={false}
                                    hideErrorMessagePopUp={hideErrorMessagePopUp}
                                    showErrorMessagePopUp={showErrorMessagePopUp}
                                />
                            </div>

                            <div className="mt-4">
                                <PricingGroupGrid
                                    headers={valanceAndReturnsHeaders}
                                    rows={valanceAndReturns}
                                    activeItemClassId={activeItemClassId}
                                    handleInputChange={(e) =>
                                        handlePricingGridsInputsChange({
                                            e,
                                            gridName:
                                                "valanceAndReturns",
                                        })
                                    }
                                    errorFields={errorFields}
                                    firstError={firstError}
                                    inputRequired={false}
                                    hideErrorMessagePopUp={hideErrorMessagePopUp}
                                    showErrorMessagePopUp={showErrorMessagePopUp}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <SectionTitle>EXTRA LARGE SHADES</SectionTitle>
                        <div className="flex flex-col">
                            <div className="flex flex-row items-center mb-2">
                                <span>For finished lengths over</span>&nbsp;
                                <div className="relative">
                                    <PopUpMessageContainer className={`${firstError && firstError.show && firstError.name === 'extraLargePanelsLengthInches' && 'active'}`}>
                                        <span>{'Please fill out this field.'}</span>
                                    </PopUpMessageContainer>
                                    <FormInput
                                        id="extraLargePanelsLengthInches"
                                        name="extraLargePanelsLengthInches"
                                        className={`${formInputStyleClass} ${errorFields && errorFields.length > 0 && errorFields.some(i => i.name === 'extraLargePanelsLengthInches') ? 'error-pleatType' : ''}`}
                                        type="text"
                                        value={extraLargePanelsLengthInches}
                                        onChange={(e) =>
                                            handlePricingInputChange(
                                                e,
                                                "extraLargePanelsLengthInches"
                                            )
                                        }
                                        onBlur={hideErrorMessagePopUp}
                                        onFocus={showErrorMessagePopUp}
                                        uiReportOnBlur={false}
                                        disabled={false}
                                        required={false}
                                        uiDisabled={false}
                                        autoComplete={autoCompleteOffSetting}
                                        list="autocompleteOff"
                                        placeholder="100"
                                    />
                                </div>
                                <span className="ml-2">inches, add $</span>
                                &nbsp;
                                <div className="relative">
                                    <PopUpMessageContainer className={`${firstError && firstError.show && firstError.name === 'extraLargePanelsLengthPricing' && 'active'}`}>
                                        <span>{'Please fill out this field.'}</span>
                                    </PopUpMessageContainer>
                                    <FormInput
                                        id="extraLargePanelsLengthPricing"
                                        name="Client Pricing Value"
                                        className={`${formInputStyleClass} ${errorFields && errorFields.length > 0 && errorFields.some(i => i.name === 'extraLargePanelsLengthPricing') ? 'error-pleatType' : ''}`}
                                        type="text"
                                        value={extraLargePanelsLengthPricing}
                                        onChange={(e) =>
                                            handlePricingInputChange(
                                                e,
                                                "extraLargePanelsLengthPricing"
                                            )
                                        }
                                        onBlur={hideErrorMessagePopUp}
                                        onFocus={showErrorMessagePopUp}
                                        uiReportOnBlur={false}
                                        disabled={false}
                                        required={false}
                                        uiDisabled={false}
                                        autoComplete={autoCompleteOffSetting}
                                        list="autocompleteOff"
                                        placeholder="3"
                                    />
                                </div>
                                &nbsp;
                                <span>/ sq ft</span>
                            </div>
                            <div className="flex flex-row items-center">
                                <span>For finished widths over</span>&nbsp;
                                <div className="relative">
                                    <PopUpMessageContainer className={`${firstError && firstError.show && firstError.name === 'extraLargePanelsWidthInches' && 'active'}`}>
                                        <span>{'Please fill out this field.'}</span>
                                    </PopUpMessageContainer>
                                    <FormInput
                                        id="extraLargePanelsWidthInches"
                                        name="extraLargePanelsWidthInches"
                                        className={`${formInputStyleClass} ${errorFields && errorFields.length > 0 && errorFields.some(i => i.name === 'extraLargePanelsWidthInches') ? 'error-pleatType' : ''}`}
                                        type="text"
                                        value={extraLargePanelsWidthInches}
                                        onChange={(e) =>
                                            handlePricingInputChange(
                                                e,
                                                "extraLargePanelsWidthInches"
                                            )
                                        }
                                        onBlur={hideErrorMessagePopUp}
                                        onFocus={showErrorMessagePopUp}
                                        uiReportOnBlur={false}
                                        disabled={false}
                                        required={false}
                                        uiDisabled={false}
                                        autoComplete={autoCompleteOffSetting}
                                        list="autocompleteOff"
                                        placeholder="60"
                                    />
                                </div>
                                <span className="ml-2">inches , add $</span>
                                &nbsp;
                                <div className="relative">
                                    <PopUpMessageContainer className={`${firstError && firstError.show && firstError.name === 'extraLargePanelsWidthPricing' && 'active'}`}>
                                        <span>{'Please fill out this field.'}</span>
                                    </PopUpMessageContainer>
                                    <FormInput
                                        id="extraLargePanelsWidthPricing"
                                        name="extraLargePanelsWidthPricing"
                                        className={`${formInputStyleClass} ${errorFields && errorFields.length > 0 && errorFields.some(i => i.name === 'extraLargePanelsWidthPricing') ? 'error-pleatType' : ''}`}
                                        type="text"
                                        value={extraLargePanelsWidthPricing}
                                        onChange={(e) =>
                                            handlePricingInputChange(
                                                e,
                                                "extraLargePanelsWidthPricing"
                                            )
                                        }
                                        onBlur={hideErrorMessagePopUp}
                                        onFocus={showErrorMessagePopUp}
                                        uiReportOnBlur={false}
                                        disabled={false}
                                        required={false}
                                        uiDisabled={false}
                                        autoComplete={autoCompleteOffSetting}
                                        list="autocompleteOff"
                                        placeholder="3"
                                    />
                                </div>
                                &nbsp;
                                <span>/ sq ft</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mt-5">
                        <SectionTitle>DRAPERY PINNING</SectionTitle>
                        <div className="flex flex-row items-center">
                            <span>Add $</span>&nbsp;
                            <FormInput
                                id="draperyPinningWidthPricing"
                                name="draperyPinningWidthPricing"
                                className={formInputStyleClass}
                                type="text"
                                value={draperyPinningWidthPricing}
                                onChange={(e) =>
                                    handlePricingInputChange(
                                        e,
                                        "draperyPinningWidthPricing"
                                    )
                                }
                                uiReportOnBlur={false}
                                disabled={false}
                                required={true}
                                uiDisabled={false}
                                autoComplete={autoCompleteOffSetting}
                                list="autocompleteOff"
                            />
                            &nbsp;
                            <span>/width</span>
                        </div>
                    </div> */}
                    <div className="mt-5">
                        <div className="flex flex-row">
                            <SectionTitle>SPECIALTY FABRICS</SectionTitle>
                            <TooltipPopup
                                text={
                                    <TooltipContainer>
                                        <TooltipText>
                                            <div>
                                                <p className="leading-6 m-0">
                                                    This mark-up is applied to
                                                    the total fabrication price
                                                    when the
                                                </p>
                                                <div className="float-left mr-[8px]">
                                                    <img
                                                        src={
                                                            TooltipSpecialtyFabricHandlingChecked
                                                        }
                                                        alt="Image"
                                                        className="border max-h-[22px]"
                                                    />
                                                </div>
                                                <p className="leading-6 m-0">
                                                    option is selected for a
                                                    fabric on a order. If more
                                                    than one fabric requires
                                                    special handling on an
                                                    order, the mark-up will only
                                                    be applied once.
                                                </p>
                                            </div>
                                        </TooltipText>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal"
                                closeOnClick
                            />
                        </div>

                        <div className="flex flex-row items-center">
                            <span>
                                Mark-up total fabrication price for items using
                                specialty fabrics
                            </span>
                            &nbsp;
                            <div className="relative">
                                <PopUpMessageContainer className={`${firstError && firstError.show && firstError.name === 'specialityFabricsMarkUpPercentage' && 'active'}`}>
                                    <span>{'Please fill out this field.'}</span>
                                </PopUpMessageContainer>
                                <FormInput
                                    id="specialityFabricsMarkUpPercentage"
                                    name="specialityFabricsMarkUpPercentage"
                                    className={`${formInputStyleClass} ${errorFields && errorFields.length > 0 && errorFields.some(i => i.name === 'specialityFabricsMarkUpPercentage') ? 'error-pleatType' : ''}`}
                                    type="text"
                                    value={specialityFabricsMarkUpPercentage}
                                    onChange={(e) =>
                                        handlePricingInputChange(
                                            e,
                                            "specialityFabricsMarkUpPercentage",
                                            "percentage"
                                        )
                                    }
                                    onBlur={hideErrorMessagePopUp}
                                    onFocus={showErrorMessagePopUp}
                                    uiReportOnBlur={false}
                                    disabled={false}
                                    required={false}
                                    uiDisabled={false}
                                    autoComplete={autoCompleteOffSetting}
                                    list="autocompleteOff"
                                    placeholder="20"
                                />
                            </div>
                            &nbsp;
                            <span>%</span>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="flex flex-row">
                            <SectionTitle>EMBELLISHMENTS</SectionTitle>
                            <TooltipPopup
                                text={
                                    <TooltipContainer>
                                        <TooltipText>
                                            <b>BANDING:</b>
                                            &nbsp;Fabric (usually a contrast
                                            fabric) seamed to the main fabric
                                            around the border or inset within
                                            the drapery.
                                            <br />
                                            <br />
                                            <b>CORDING:</b>
                                            &nbsp;Rope that is sewn into a seam
                                            or edge. This can be a pre-made
                                            decorative cording or welt cording
                                            (non-decorative cording covered with
                                            fabric).
                                            <br />
                                            <br />
                                            <b>FLANGE:</b>&nbsp;A strip of
                                            fabric that is folded in half and
                                            sewn into the seam or edge of the
                                            drapery.
                                            <br />
                                            <br />
                                            <b>TRIM:</b>&nbsp;Strips of fabric
                                            or pre-made trim applied on top of
                                            the main fabric.
                                            <br />
                                            <br />
                                        </TooltipText>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal"
                                closeOnClick
                            />
                        </div>

                        <div className="mt-4">
                            <PricingGroupGrid
                                headers={embellishmentsPricingHeaders}
                                rows={embellishmentsWithPricing}
                                activeItemClassId={activeItemClassId}
                                handleInputChange={(e) =>
                                    handlePricingGridsInputsChange({
                                        e,
                                        gridName: "embellishmentsWithPricing",
                                    })
                                }
                                errorFields={errorFields}
                                firstError={firstError}
                                inputRequired={false}
                                hideErrorMessagePopUp={hideErrorMessagePopUp}
                                showErrorMessagePopUp={showErrorMessagePopUp}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isPleatModalOpen && (
                <PleatModal
                    isPleatModalOpen={isPleatModalOpen}
                    onPleatModalClose={closePleatModal}
                    dispatch={dispatch}
                    formFields={formFields}
                    draperyItemDetailsFields={draperyItemDetailsFields}
                    itemId={itemId}
                />
            )}
        </div>
    );
};

export default Pricing;
