import { ApplicationModule, RootState, UserModule } from "../redux";
import { ClickOutside, dimmable } from "@ramble/ramble-ui";
import { DispatchProp, connect } from "react-redux";
import React, { PureComponent } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import styled, { theme } from "../theme";

import { AccountSettingsContainer } from "../pages/account_settings";
import { AdminContainer } from "../pages/admin";
import { AppHeader } from "../components/app_header/app_header";
// import { ApplicationError } from "../components/application/error";
// import { ApplicationLoader } from "../components/application/loader";
import { CalculatorContainer } from "../components/calculator/calculator";
import { ClientsContainer } from "../pages/clients";
import { Overview } from "../pages/overview";
import MyShopPage from "../pages/my-shop";
import { NotificationStackContainer } from "../components/notifications/notification_stack";
import QuipaHorizontal from "../assets/icons/quipa_horizontal.svg";
import { Sidebar } from "../components/sidebar/sidebar";
import { SuppliersContainer } from "../pages/suppliers";
import SupportModal from "../modals/support-modal";
import { UserSettingsContainer } from "../pages/user_settings";
import {ApplicationContext} from "../ApplicationContext";
interface MainApplicationContainerProps {
    uiSidebarFull: boolean;
}

/**
 * General application container
 */
const MainApplicationContainer = styled.div<MainApplicationContainerProps>`
    height: 100%;
    width: 100%;
    margin: 0;
    display: grid;
    grid-template-columns: ${theme.elements.sidebarSmallSize}px 1fr;
    grid-template-rows: ${theme.elements.headerFullSize}px 1fr;
    grid-template-areas:
        "sidebar_control header"
        "sidebar main";
    grid-gap: 0 0;
    justify-items: stretch;
    align-items: stretch;
`;

const HeaderContent = styled.div`
    grid-area: header;
    background: ${theme.colors.primary};
    color: ${theme.getContrastTextColor(theme.colors.primary)};
    position: fixed;
    top: 0;
    left: 0;
    height: 65px;
    width: 100%;
    z-index: 999;
`;

const QuipaLogoContainer = styled.div`
    cursor: pointer;
`;

const QuipaLogo = styled(QuipaHorizontal)`
    float: left;
    margin-left: 11px;
    height: 65px;
`;

const MainContent = styled.main`
    grid-area: main;
    background: #fff;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    ${dimmable};
    height: auto;
`;

const SidebarContent = styled.aside`
    grid-area: sidebar;
    background: ${theme.colors.primary};
    color: ${theme.getContrastTextColor(theme.colors.primary)};
    position: fixed;
    left: 0;
    top: 65px;
    height: 100%;
    z-index: 500;
`;

// tslint:disable-next-line:no-empty-interface
export interface ApplicationOwnProps extends RouteComponentProps {}

interface ApplicationProps extends ApplicationOwnProps {
    /**
     * Auth token
     */
    token?: string | null;
    /**
     * True when app was initially loaded
     */
    appLoaded: boolean;
    /**
     * User full name
     */
    userName: string;
    /**
     * User role
     */
    userRole: "admin" | "office_worker";
    /**
     * First name
     */
    firstName: string;
    /**
     * Last name
     */
    lastName: string;
    /**
     * AvatarId
     */
    avatarId?: string | null;
    /**
     * Account Id
     */
    accountId: number;
    /**
     * Email
     */
    userEmail: string;
}

interface State {
    /**
     * True when sidebar is fully expanded
     */
    fullSidebar: boolean;
    /**
     * Initial loading error
     */
    loadingError?: string;
    /**
     * Support Modal
     */
    isSupportModalOpen?: boolean;

    previousStatus?: string | null;

    isOpen: boolean;

    isOpenModal: boolean;

    cantLogOut: boolean;

    triggerModal: boolean;
    // shouldRenderOverview: boolean;
}

export class Application extends PureComponent<
    ApplicationProps & DispatchProp,
    State
> {
    static contextType = ApplicationContext;
    public state: State = {
        fullSidebar: false,
        isOpen: false,
        isOpenModal: false,
        previousStatus: localStorage.getItem("status"),
        cantLogOut: false,
        triggerModal: false,
        // shouldRenderOverview: false,
    };

    public async componentDidMount(): Promise<void> {
        const { dispatch, history } = this.props;
        // Bail if application already loaded
        const appLoaded = localStorage.getItem("appLoaded");
        const { user, setUser } = this.context;
        const token = user.user.token || "";
        if(token){
            const jwtPayload = JSON.parse(atob(token.split('.')[1]));
            const { exp, accountId } = jwtPayload;
            let quipaStore: any = localStorage.getItem("quipa-store");
            if(quipaStore){
                quipaStore = JSON.parse(quipaStore);
            }

            if(new Date().getTime() > exp * 1000) {
                delete user.user.token;
                delete user.user.refreshToken;
                localStorage.setItem("quipa-store", JSON.stringify({...quipaStore, user: user.user}));
                setUser(user);
                localStorage.removeItem("appLoaded");
                localStorage.setItem(`logout-event-${accountId}`, 'logout' + Math.random());
                history.push("/login");
            }
        }


        if (!token || appLoaded) {
            return;
        }
        
       
        const res: any = await dispatch(
            ApplicationModule.initialAppSync(token)
        );

        localStorage.setItem("accountId", res.account.id);
        localStorage.setItem("firstName", res.user.firstName);
        localStorage.setItem("lastName", res.user.lastName);
        localStorage.setItem("role", res.user.role);
        localStorage.setItem("avatarId", res.account.avatarId);
        localStorage.setItem("status", res.account.type);

        // const loaded = localStorage.getItem("status") !== "subscribed";

        // if (loaded) {
        //     return;
        // }

        if (res.error) {
            if (res.code === 401) {
                history.replace({
                    pathname: "/login",
                    state: {
                        redirectAfterLogin: location.pathname,
                        redirectAfterLoginSearch: location.search,
                    },
                });
            } else {
                this.setState({
                    loadingError: res.message,
                });
            }
        } else if (!res.account.loginAllowed) {
            // credit card required
            history.replace({
                pathname: "/card",
                state: {
                    redirectAfterLogin: location.pathname,
                    redirectAfterLoginSearch: location.search,
                },
            });
        }
        localStorage.setItem("appLoaded", String(true))
    }
    
    public render(): JSX.Element {
        const { fullSidebar } = this.state;
        const {
            appLoaded,
            userName,
            firstName,
            lastName,
            userRole,
            userEmail,
            accountId,
        } = this.props;
        if (!appLoaded) {
        }
        return (
            <MainApplicationContainer uiSidebarFull={fullSidebar}>
                <HeaderContent>
                    <QuipaLogoContainer onClick={this.goHome}>
                        <QuipaLogo />
                    </QuipaLogoContainer>
                    <AppHeader
                        userName={userName}
                        firstName={firstName}
                        lastName={lastName}
                        userEmail={userEmail}
                        accountId={accountId}
                        onNavigateTo={this.handleNewNavigation}
                        isAdmin={userRole === "admin"}
                        onPlusClicked={this.handlePlusClicked}
                        isOpen={this.state.isOpen}
                        handlePlusClick={this.handlePlusClick}
                        handleCloseModal={this.handleCloseBtnModal}
                        handleTriggerModal={this.handleTriggerModal}
                    />
                </HeaderContent>
                <SidebarContent
                    id="sidebar"
                    onMouseEnter={this.toggleSidebar}
                    onMouseLeave={this.toggleSidebar}
                >
                    {fullSidebar && (
                        <ClickOutside
                            rootNodeId="sidebar"
                            onOutsideClick={this.toggleSidebar}
                            ignoreIds="sidebar-control"
                        />
                    )}
                    <Sidebar
                        // expanded={fullSidebar}
                        onNavigateTo={this.handleNewNavigation}
                        activeValue={this.getActiveSidebarItem()}
                    />
                </SidebarContent>
                <MainContent>
                    <Switch>
                        <Redirect exact from="/" to="/overview" />
                        {/* tslint:disable-next-line: jsx-alignment */}
                        {
                            <Route
                                path="/calculator"
                                render={(props) => (
                                    <CalculatorContainer
                                        key={
                                            (props.location.state &&
                                                props.location.state.tempKey) ||
                                            "calc"
                                        }
                                        {...props}
                                        handleCloseModal={this.handleCloseModal}
                                        handleNotSavedOrder={this.handleNotSavedOrder}
                                        handleNewNavigation={this.handleNewNavigation}
                                        triggerModal={this.state.triggerModal}
                                        cantLogOut={this.state.cantLogOut}
                                    />
                                )}
                            />
                        }

                        <Route
                            path="/overview"
                            render={(props: RouteComponentProps) => (
                                <Overview
                                    isOpenModal={this.state.isOpenModal}
                                    handleCloseModal={this.handleCloseModal}
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            path="/clients"
                            render={(props: RouteComponentProps) => (
                                <ClientsContainer
                                    isOpenModal={this.state.isOpenModal}
                                    handleCloseModal={this.handleCloseModal}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/myshop"
                            render={(props: RouteComponentProps) => (
                                <MyShopPage
                                    isOpenModal={this.state.isOpenModal}
                                    handleCloseModal={this.handleCloseModal}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/suppliers"
                            render={(props: RouteComponentProps) => (
                                <SuppliersContainer
                                    isOpenModal={this.state.isOpenModal}
                                    handleCloseModal={this.handleCloseModal}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/admin_settings"
                            component={AdminContainer}
                        />
                        <Route
                            path="/account_settings"
                            component={AccountSettingsContainer}
                        />
                        <Route
                            path="/user_settings"
                            component={UserSettingsContainer}
                        />
                    </Switch>
                </MainContent>
                <NotificationStackContainer />
                {this.state.isSupportModalOpen && (
                    <SupportModal
                        isModalOpen={this.state.isSupportModalOpen}
                        onModalClose={this.toggleSupportModal}
                    />
                )}
            </MainApplicationContainer>
        );
    }

    private handleTriggerModal = () => {
        this.setState(prev => {
            return {
                ...prev,
                triggerModal: !prev.triggerModal
            }
        })
    }

    private handleNotSavedOrder = (changes: boolean): void => {
        this.setState(prev => {
            return {
                ...prev,
                cantLogOut: changes
            }
        })
    }

    private handleNewNavigation = (val: string, saveCase?: boolean): void => {
        const { history, dispatch, accountId } = this.props;
        switch (val) {
            case "support": {
                this.setState({ isSupportModalOpen: true });
                return;
            }
            case "logout": {
                if (this.state.cantLogOut && !saveCase) {
                    return
                }
                const {user, setUser} = this.context;
                dispatch(UserModule.logout());
                localStorage.setItem("isReloaded", "false");
                localStorage.setItem("refreshed", "false");
                localStorage.setItem(
                    `logout-event-${accountId}`,
                    "logout" + Math.random()
                );

                const setOnlyEmail = {
                    user: {
                        email: this.props.userEmail,
                    },
                };

                localStorage.setItem(
                    "quipa-store",
                    JSON.stringify(setOnlyEmail)
                );
                localStorage.removeItem("accountId");
                localStorage.removeItem("showModal");
                localStorage.removeItem("firstName");
                localStorage.removeItem("lastName");
                localStorage.removeItem("role");
                localStorage.removeItem("avatarId");
                localStorage.removeItem("logoId");

                document.cookie.split(";").forEach((item) => {
                    const cookieName = item.trim().split("=")[0];
                    if (cookieName.startsWith("crisp-client")) {
                        const cookieDomain = ".quipa.com";
                        const cookiePath = "/";

                        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${cookiePath}; domain=${cookieDomain};`;
                        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${cookiePath};`;
                    }
                });
                delete user.user.token;
                delete user.user.refreshToken;
                setUser(user);
                history.push("/login");
                return;
            }
        }
       
        history.push(`/${val}`);
    };

    private handlePlusClicked = async (
        e: React.MouseEvent<HTMLLIElement>
    ): Promise<void> => {
        const { history } = this.props;
        if (e.currentTarget.textContent === "Add Client") {
            history.push("/clients", { tempKey: Math.random() });
            this.setState({ isOpenModal: true });
        } else if (e.currentTarget.textContent === "Add Supplier") {
            history.push("/suppliers", { tempKey: Math.random() });
            this.setState({ isOpenModal: true });
        } else if (e.currentTarget.id === "add_drapery_order_btn") {
            history.push("/calculator?type=drapery-order", { tempKey: Math.random(), type: "drapery-order" });
        } else if (e.currentTarget.id === "add_custom_order_btn") {
            history.push("/calculator?type=custom-order", { tempKey: Math.random(), type: "custom-order" })
        } else if (e.currentTarget.id === 'roman_shade_btn') {
            history.push("/calculator?type=roman-shade-order", { tempKey: Math.random(), type: "roman-shade-order"});
        } else {
            history.push("/myshop", { tempKey: Math.random() });
            this.setState({ isOpenModal: true });
        }
        this.setState({
            isOpen: false,
        });
    };
    private handlePlusClick = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };
    public handleCloseModal = () => {
        this.setState({ isOpenModal: false });
    };

    public handleCloseBtnModal = () => {
        this.setState({ isOpen: false });
    };
    private toggleSidebar = (): void => {
        this.setState({
            fullSidebar: !this.state.fullSidebar,
        });
    };

    private toggleSupportModal = (): void => {
        this.setState({
            isSupportModalOpen: !this.state.isSupportModalOpen,
        });
    };

    private goHome = (): void => {
        const { history } = this.props;
        history.push("/");
    };

    private getActiveSidebarItem(): string | undefined {
        const {
            location: { pathname },
        } = this.props;
        if (pathname === "/") {
            return "clients";
        } else {
            return pathname.replace(/[^0-9a-zA-Z]/g, "");
        }
    }
}

function mapStateToProps(
    state: RootState,
    ownProps: ApplicationOwnProps
): ApplicationProps {
    const user = localStorage.getItem("quipa-store");
    const token = user ? JSON.parse(user).token : "";
    const email = user ? JSON.parse(user).user.email : "";
    const firstName = localStorage.getItem("firstName") || "";
    const lastName = localStorage.getItem("lastName") || "";
    const avatarId = localStorage.getItem("avatarId");
    const role =
        localStorage.getItem("role") === "admin" ||
        localStorage.getItem("role") === "office_worker"
            ? (localStorage.getItem("role") as "admin" | "office_worker")
            : "office_worker";

    return {
        ...ownProps,
        appLoaded: state.application.loaded,
        token,
        userName: firstName,
        userRole: role,
        firstName,
        lastName,
        avatarId,
        accountId: parseFloat(localStorage.getItem("accountId") || "0"),
        userEmail: email,
    };
}

export const ApplicationContainer = connect(mapStateToProps)(Application);
