import { createContext } from "react";

export interface ApplicationContextType {
    user: string | null; 
    setUser: (user: string | null) => void; 
}

export const ApplicationContext = createContext<ApplicationContextType>({
    user: null,
    setUser: () => {},
});