import { Checkbox, Input, InputProps } from "@ramble/ramble-ui";
import {
    CalculatorFormField,
    CalculatorFormFieldHeader,
    CalculatorFormFieldLabel,
    DraperyFullnessTooltip,
    TooltipContainer,
    TooltipHeader,
    TooltipPopup,
    TooltipText,
    TooltipWidePopup,
    TooltipImgContainer,
} from "./styles";

import TooltipBackOfDrapery from "../../assets/images/tooltip/back-of-drapery.png";
import TooltipHemSideView from "../../assets/images/tooltip/hem-side-view.png";

import SettingsIcon from '../../assets/icons/icons8-settings.svg'

import React, {
    ChangeEvent,
    FC,
    forwardRef,
    PureComponent,
    ReactNode,
} from "react";
import SelectListE from "../../components/UI/select-list";
import { DraperyModule } from "../../redux";
import styled, { css } from "../../theme";
import { highlightStyle } from "./calculator";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";
import { TooltipSvgContainer } from "../my-shop/styles";
import TooltipOverlap from "../../assets/icons/tooltip/overlap.svg";
import TooltipReturnAndLeadEdge from "../../assets/icons/tooltip/drapery-return-and-lead-edge.svg";


const unitList = ["yard(s)", "%"];

const BuckramField = styled.div<FormContainerProps>`
    width: 177px;
    & > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
`;

const FullnessField = styled.div<FormContainerProps>`
    line-height: 16px;
    display: inline-block;
`;
const ExcessBox = styled.span`
    line-height: 35px;
    font-size: 18px;
    width: 80%;
    padding-left: 0.2rem;
`;
const SelectFullness = styled.select`
    width: 150px;
    text-align: center;
    line-height: 16px;
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5.25px 6.75px;
    font-size: 1em;
    background-color: #fff;
    border: 1px solid #3b97b1 !important;
    border-radius: 5px;
`;

const WorkroomFormWrapper = styled.div`
    width: 100%;
    background-color: #fff;
`;

const MoreOptionsDiv = styled.div`
    margin: 5px auto 0 auto;
    max-width: 900px;
    min-width: 570px;
    @media (min-width: 1120px) and (max-width: 1678px) {
        width: 70%;
    }
    @media (min-width: 768px) and (max-width: 1120px) {
        width: 90%;
    }
    @media (max-width: 768px) {
        width: 100%;
    }

    & .edit_construction_container{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & .edit_construction_content{
            border: 1px solid ${({ theme }) => theme.colors.primary};
            border-radius: 6px;
            gap: 5px;
            padding: 7px 10px 7px 7px;
            cursor: pointer;

            & .construction_icon{
                transform: rotate(0deg);
                transition: all 1s ease;
            }
            &:hover{
                & .construction_icon{
                    transform: rotate(180deg);
                }
            }
            

            &>p{
                color: ${({ theme }) => theme.colors.primary};
                font-weight: 600;
            }

            &.disabled {
                border-color: #d4d4d4;

                &>p{
                    color: #d4d4d4;
                }
                    & .construction_icon{
                        opacity: 0.4;
                    }
            }

        }
    }
`;

const MoreOptionsDivSection = styled.div`
    background-color: inherit;
    border: 1px solid #dadada;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 14px;
`;

const MoreOptionsDivSectionHeader = styled.div`
    background-color: #d6eaf0;
    color: #3b97b1;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 14px 0px 14px 19px;

    hr {
        width: 80%;
        margin: 5px 0 0 0;
        border-left: none;
        border-right: none;
        border-bottom: none;
    }
`;

const MoreOptionsDivSectionSubHeader = styled.div`
    color: #3b97b1;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 16px 0px 16px 19px;
`;

const SettingsCheckbox = styled(Checkbox)`
    width: 80%;
    align-items: start;
    color: #545454;
    font-family: Poppins;
    font-size: 18px;
    margin-bottom: auto;

    > span.label {
        border: 0 !important;
        line-height: 35px;
    }

    > svg {
        margin-top: 8px;
    }
    ${(props) =>
        props.className === "excess-box" &&
        css`
            width: 3%;
        `}
`;

interface FieldProps {
    /**
     * Field label
     */
    label?: string;
    colored?: boolean;
    bordered?: boolean;
    /**
     * True to render as subfield with margin
     */
    subField?: boolean;
    children: ReactNode;
    className?: string;

    /**
     * Label prop
     */
    title?: boolean;
}

const FieldE: FC<FieldProps> = (props) => (
    <div className={props.className}>
        {props.label && <div className="field-label">{props.label}</div>}
        {props.children}
    </div>
);

/**
 * Single field in options
 */
const Field = styled(FieldE)`
    display: flex;
    margin: 0 0 0 20px;
    align-items: center;
    color: #545454;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${(props) =>
        props.bordered &&
        css`
            border-bottom: 1px solid #e6e6e6;
            width: 95%;
        `}
    > .field-label {
        margin-right: 5px;
        display: inline-block;
        min-width: 180px;
        @media screen and (max-width: 992px) {
            min-width:140px;
        }
        ${(props) =>
        props.colored &&
        css`
                color: #306f82;
                margin-right: 86px;
            `}
    }

    ${(props) =>
        props.label === "" &&
        css`
            padding-left: 90px;
        `}

    ${(props) =>
        props.subField &&
        css`
            display: inline-block;
            margin: 5px 0 0 150px;
        `};

    ${(props) =>
        props.title === true &&
        css`
            color: #3b97b1;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-top: 16px;
            margin-right: 120px;
            text-align: right;
            display: unset;
        `}
`;

const InputLabel = styled.div`
    margin-left: 15px;
    margin-right: 15px;
    display: inline-block;
`;

const LabeledInputE = forwardRef<
    HTMLInputElement,
    InputProps & { label?: string }
>(({ label, ...props }, ref) => (
    <>
        <Input {...props} ref={ref} />
        {label && <InputLabel>{label}</InputLabel>}
    </>
));

const SettingsLabeledInput = styled(LabeledInputE)`
    width: 59px;
    height: 32px;
    border: solid #545454 1px;
    padding: 0.4em 0.6em !important;
    line-height: 16px;
    margin-left: 24px;
    @media screen and (max-width: 992px) {
        margin-left:0px;
        }
`;

const SettingsHalfLabel = styled.div`
    width: 151px;
    height: 40px;
    padding-top: 10px;
`;

const SettingsColoredHalfLabel = styled(SettingsHalfLabel)`
    color: #545454;
    font-family: Poppins;
    font-size: 16px;
    text-align: center;
`;

const HorizontalBorder = styled.div`
    height: 60px;
    margin: 0 40px;
    border-left: 1px #e6e6e6 solid;
`;

const HorizontalSpace = styled.div`
    height: 40px;
    margin: 0 20px;
`;

const SelectList = styled(SelectListE)`
    width: 140px;
    line-height: 16px;
    display: inline-block;
`;
interface FormContainerProps {
    uiDisabled?: boolean;
}

export interface WorkroomSettingsProps {
    draperyOrderDesign: DraperyModule.DraperyOrderDesign;
    disabled: boolean;
    highlightedSection: string | null | undefined;
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSelectListChange(value: string | number, name?: string): void;
    handleBuckramSelect(e: ChangeEvent<HTMLSelectElement>): void;
    handleFullnessSelect(e: ChangeEvent<HTMLSelectElement>): void;
    formErrorID?: string;
    embellishmentsList: any[];
    data?: any;
    workroomSetting: WorkroomSetting | null;
    openConstructionDetailsEditModal?(): void;
}

// tslint:disable-next-line:no-empty-interface
interface State { }

export class DrapperyOrderWorkroomSettings extends PureComponent<
    WorkroomSettingsProps,
    State,
    FormContainerProps
> {
    public constructor(props: WorkroomSettingsProps) {
        super(props);
        this.state = {
            embellishmentsList: this.props.embellishmentsList || [],
        };
    }

    public handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (
            e.keyCode === 69 ||
            e.key === "e" ||
            e.keyCode === 188 ||
            e.keyCode === 187 ||
            e.keyCode === 61 ||
            e.keyCode === 107
        ) {
            e.preventDefault();
        }
       if (
           e.currentTarget.valueAsNumber &&
           (e.keyCode === 189 || e.keyCode === 173 || e.keyCode === 109)
       ) {
           e.preventDefault();
       }
       if (
           e.currentTarget.valueAsNumber.toString().includes("0") && e.key === '-'
       ) {
           e.preventDefault();
       }
       if (
           e.currentTarget.validity.badInput &&
           (e.keyCode === 189 || e.keyCode === 173 || e.keyCode === 109)
       ) {
           e.preventDefault();
       }
       if (
           e.currentTarget.value.includes("-") &&
           (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
       ) {
           e.preventDefault();
       }
    }

    public render(): JSX.Element {
        const { draperyOrderDesign } = this.props;
        return (
            <WorkroomFormWrapper>
                <MoreOptionsDiv>
                    <div className="edit_construction_container">
                        <div onClick={() => {
                            if (!this.props.disabled) {
                                this.props.openConstructionDetailsEditModal && this.props.openConstructionDetailsEditModal()
                            }
                        }} className={`flex items-center justify-center edit_construction_content ${this.props.disabled ? 'disabled' : ''}`}>
                            <SettingsIcon className="construction_icon" style={{ width: '25px', height: '25px' }} />
                            <p>Edit Settings</p>
                        </div>
                    </div>
                    <MoreOptionsDivSection
                        style={{ marginTop: "20px", marginBottom: "40px" }}
                    >
                        <MoreOptionsDivSectionHeader
                            style={{ display: "flex", alignItems: "center", position: "relative" }}
                        >
                            HEMS
                            <TooltipWidePopup
                                text={
                                    <TooltipContainer>
                                        <TooltipHeader>HEM SIZE</TooltipHeader>
                                        <TooltipText>
                                            The finished size of a hem.
                                        </TooltipText>
                                        <TooltipImgContainer>
                                            <img
                                                src={TooltipBackOfDrapery}
                                                alt="Tooltip"
                                            />
                                        </TooltipImgContainer>
                                        <TooltipHeader>
                                            HEM ALLOWANCE
                                        </TooltipHeader>
                                        <TooltipText>
                                            The total amount of fabric needed to
                                            create a hem including the folded
                                            fabric.
                                            <br />
                                            <br />
                                            Hem Allowance = Hem Size + Fold
                                            <br />
                                            <br />
                                            For example, if the top hem size is
                                            4” and I use a double turned hem, I
                                            need an 8” allowance of fabric to
                                            create the folded hem. 4” Hem size +
                                            4” Fold = 8” hem allowance.
                                        </TooltipText>
                                        <TooltipImgContainer>
                                            <img
                                                src={TooltipHemSideView}
                                                alt="Tooltip"
                                            />
                                        </TooltipImgContainer>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal"
                                closeOnClick
                            />
                        </MoreOptionsDivSectionHeader>

                        <Field className="mb-5">
                            <SettingsColoredHalfLabel
                                style={{ marginLeft: "240px" }}
                            >
                                Size
                            </SettingsColoredHalfLabel>
                            <HorizontalSpace />
                            <SettingsColoredHalfLabel
                                style={{ marginLeft: "60px" }}
                            >
                                Allowance
                            </SettingsColoredHalfLabel>
                        </Field>
                        <Field label="Top Hem" bordered>
                            <HorizontalBorder />

                            <SettingsLabeledInput
                                id="draperyOrderDesign.customHEMSJson.topHemFinishedSize"
                                name="draperyOrderDesign.customHEMSJson.topHemFinishedSize"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customHEMSJson.topHemFinishedSize"
                                        ? highlightStyle
                                        : {}
                                }
                                value={
                                    draperyOrderDesign.customHEMSJson
                                        .topHemFinishedSize !== undefined
                                        ? draperyOrderDesign.customHEMSJson
                                            .topHemFinishedSize
                                        : ""
                                }
                                type="number"
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                                label={window.innerWidth <= 992 ? "in." : "inches"}
                                // autoFocus
                                step={0.001}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                            <HorizontalBorder />
                            <SettingsLabeledInput
                                id="draperyOrderDesign.customHEMSJson.topHemFabricAllowance"
                                name="draperyOrderDesign.customHEMSJson.topHemFabricAllowance"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customHEMSJson.topHemFabricAllowance"
                                        ? highlightStyle
                                        : {}
                                }
                                type="number"
                                value={
                                    draperyOrderDesign.customHEMSJson
                                        .topHemFabricAllowance !== undefined
                                        ? draperyOrderDesign.customHEMSJson
                                            .topHemFabricAllowance
                                        : ""
                                }
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                                label={window.innerWidth <= 992 ? "in." : "inches"}
                                // autoFocus
                                step={0.001}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                        </Field>
                        <Field label="Bottom Hem" bordered>
                            <HorizontalBorder />
                            <SettingsLabeledInput
                                id="draperyOrderDesign.customHEMSJson.bottomHemFinishedSize"
                                name="draperyOrderDesign.customHEMSJson.bottomHemFinishedSize"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customHEMSJson.bottomHemFinishedSize"
                                        ? highlightStyle
                                        : {}
                                }
                                value={
                                    draperyOrderDesign.customHEMSJson
                                        .bottomHemFinishedSize !== undefined
                                        ? draperyOrderDesign.customHEMSJson
                                            .bottomHemFinishedSize
                                        : ""
                                }
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                                type="number"
                                label={window.innerWidth <= 992 ? "in." : "inches"}
                                step={0.001}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                            <HorizontalBorder />
                            <SettingsLabeledInput
                                id="draperyOrderDesign.customHEMSJson.bottomHemFabricAllowance"
                                name="draperyOrderDesign.customHEMSJson.bottomHemFabricAllowance"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customHEMSJson.bottomHemFabricAllowance"
                                        ? highlightStyle
                                        : {}
                                }
                                type="number"
                                value={
                                    draperyOrderDesign.customHEMSJson
                                        .bottomHemFabricAllowance !== undefined
                                        ? draperyOrderDesign.customHEMSJson
                                            .bottomHemFabricAllowance
                                        : ""
                                }
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                                label={window.innerWidth <= 992 ? "in." : "inches"}
                                // autoFocus
                                step={0.001}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                        </Field>
                        <Field label="Side Hem" bordered>
                            <HorizontalBorder />
                            <SettingsLabeledInput
                                id="draperyOrderDesign.customHEMSJson.sideHemFinishedSize"
                                name="draperyOrderDesign.customHEMSJson.sideHemFinishedSize"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customHEMSJson.sideHemFinishedSize"
                                        ? highlightStyle
                                        : {}
                                }
                                value={
                                    draperyOrderDesign.customHEMSJson
                                        .sideHemFinishedSize !== undefined
                                        ? draperyOrderDesign.customHEMSJson
                                            .sideHemFinishedSize
                                        : ""
                                }
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                                label={window.innerWidth <= 992 ? "in." : "inches"}
                                type="number"
                                step={0.001}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                            <HorizontalBorder />
                            <SettingsLabeledInput
                                id="draperyOrderDesign.customHEMSJson.sideHemFabricAllowance"
                                name="draperyOrderDesign.customHEMSJson.sideHemFabricAllowance"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customHEMSJson.sideHemFabricAllowance"
                                        ? highlightStyle
                                        : {}
                                }
                                value={
                                    draperyOrderDesign.customHEMSJson
                                        .sideHemFabricAllowance !== undefined
                                        ? draperyOrderDesign.customHEMSJson
                                            .sideHemFabricAllowance
                                        : ""
                                }
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                                label={window.innerWidth <= 992 ? "in." : "inches"}
                                type="number"
                                step={0.001}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                        </Field>
                        <Field>
                            <span>Buckram</span>
                            <TooltipPopup
                                text={
                                    <TooltipContainer>
                                        <TooltipText>
                                            A stiffened piece of open weave
                                            fabric that comes in varying widths
                                            that helps provide support and
                                            structure to a fabricated piece,
                                            such as the top hem of a drapery.
                                            Buckram is also known by other
                                            names, such as crinoline and
                                            interface.
                                        </TooltipText>
                                    </TooltipContainer>
                                }
                                position="top left"
                                className="normal"
                                closeOnClick
                            />
                            <HorizontalBorder style={{ marginLeft: window.innerWidth <= 992 ? "82px" : "122px" }} />

                            <BuckramField
                                className="h-14"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.buckram"
                                        ? highlightStyle
                                        : {}
                                }
                                uiDisabled={this.props.disabled}
                            >
                                <div className="h-full flex items-center justify-center py-2 px-6">
                                    <select
                                        className="w-full border rounded text-sm"
                                        name="draperyOrderDesign.buckram"
                                        onChange={
                                            this.props.handleBuckramSelect
                                        }
                                        value={draperyOrderDesign.buckram}
                                        disabled={this.props.disabled}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <option value="Ask for each order">
                                            Please select
                                        </option>
                                        <option value="Yes, include in top hem">
                                            Yes
                                        </option>
                                        <option value="No Buckram">
                                            No
                                        </option>
                                    </select>
                                </div>
                            </BuckramField>
                            <HorizontalBorder style={{ marginLeft: "29px" }} />
                        </Field>
                    </MoreOptionsDivSection>
                    <MoreOptionsDivSection style={{ marginBottom: "40px" }}>
                        <MoreOptionsDivSectionHeader>
                            CUT LENGTHS
                        </MoreOptionsDivSectionHeader>
                        <Field bordered>
                            <span>Main Fabric</span>
                            <TooltipPopup
                                text={
                                    <TooltipContainer>
                                        <TooltipText>
                                            The cut length of the main fabric
                                            for a drapery panel is the finished
                                            length plus the allowances for the
                                            top and bottom hems.
                                        </TooltipText>
                                    </TooltipContainer>
                                }
                                position="top left"
                                className="normal"
                                closeOnClick
                            />
                            <HorizontalBorder style={{ marginLeft: "101px" }} />
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <span>
                                    FL +
                                </span>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customCutLengthJson.mainFabric"
                                    name="draperyOrderDesign.customCutLengthJson.mainFabric"
                                    style={
                                        this.props.highlightedSection ===
                                            "draperyOrderDesign.customCutLengthJson.mainFabric"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        (this.props.draperyOrderDesign
                                            .customHEMSJson
                                            .topHemFabricAllowance || 0) +
                                        (this.props.draperyOrderDesign
                                            .customHEMSJson
                                            .bottomHemFabricAllowance || 0)
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "inches"}
                                    type="number"
                                    step={0.001}
                                    onChange={
                                        this.props.handleInputChangeForNumber
                                    }
                                    disabled={true}
                                    uiDisabled={true}
                                />
                            </div>
                        </Field>
                        {this.props.data.liningsList.some(
                            (lining: { name: string; active: boolean }) =>
                                lining.name === "Lining" && lining.active
                        ) ? (
                            <Field label="Lining" bordered>
                                <HorizontalBorder />
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>
                                        FL +
                                    </span>
                                    <SettingsLabeledInput
                                        id="draperyOrderDesign.customCutLengthJson.lining"
                                        name="draperyOrderDesign.customCutLengthJson.lining"
                                        style={
                                            this.props.highlightedSection ===
                                                "draperyOrderDesign.customCutLengthJson.lining"
                                                ? highlightStyle
                                                : {}
                                        }
                                        value={
                                            draperyOrderDesign
                                                .customCutLengthJson.lining !==
                                                undefined
                                                ? draperyOrderDesign
                                                    .customCutLengthJson
                                                    .lining
                                                : ""
                                        }
                                        onKeyDown={(evt) => this.handleKeyDown(evt)}
                                        label={window.innerWidth <= 992 ? "in." : "inches"}
                                        type="number"
                                        step={0.001}
                                        onChange={
                                            this.props
                                                .handleInputChangeForNumber
                                        }
                                        disabled={this.props.disabled}
                                        uiDisabled={this.props.disabled}
                                    />
                                </div>
                            </Field>
                        ) : null}

                        {this.props.data.liningsList.some(
                            (lining: { name: string; active: boolean }) =>
                                lining.name === "Interlining" && lining.active
                        ) ? (
                            <Field label="Interlining" bordered>
                                <HorizontalBorder />
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>
                                        FL +
                                    </span>
                                    <SettingsLabeledInput
                                        id="draperyOrderDesign.customCutLengthJson.interlining"
                                        name="draperyOrderDesign.customCutLengthJson.interlining"
                                        style={
                                            this.props.highlightedSection ===
                                                "draperyOrderDesign.customCutLengthJson.interlining"
                                                ? highlightStyle
                                                : {}
                                        }
                                        value={
                                            draperyOrderDesign
                                                .customCutLengthJson
                                                .interlining !== undefined
                                                ? draperyOrderDesign
                                                    .customCutLengthJson
                                                    .interlining
                                                : ""
                                        }
                                        onKeyDown={(evt) => this.handleKeyDown(evt)}
                                        onPaste={(evt: any) => evt.preventDefault()}
                                        label={window.innerWidth <= 992 ? "in." : "inches"}
                                        type="number"
                                        step={0.001}
                                        onChange={
                                            this.props
                                                .handleInputChangeForNumber
                                        }
                                        disabled={this.props.disabled}
                                        uiDisabled={this.props.disabled}
                                    />
                                </div>
                            </Field>
                        ) : null}

                        <div>
                            {!this.props.data.embellishmentsList.some(
                                (item: { checked: boolean }) => item.checked
                            ) ? (
                                <></>
                            ) : (
                                <>
                                    <MoreOptionsDivSectionSubHeader>
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            EMBELLISHMENTS
                                            <TooltipPopup
                                                text={
                                                    <TooltipContainer>
                                                        <TooltipText>
                                                            <b>BANDING:</b>
                                                            &nbsp;Fabric
                                                            (usually a contrast
                                                            fabric) seamed to
                                                            the main fabric
                                                            around the border or
                                                            inset within the
                                                            drapery.
                                                            <br />
                                                            <br />
                                                            <b>CORDING:</b>
                                                            &nbsp;Rope that is
                                                            sewn into a seam or
                                                            edge. This can be a
                                                            pre-made decorative
                                                            cording or welt
                                                            cording
                                                            (non-decorative
                                                            cording covered with
                                                            fabric).
                                                            <br />
                                                            <br />
                                                            <b>FLANGE:</b>
                                                            &nbsp;A strip of
                                                            fabric that is
                                                            folded in half and
                                                            sewn into the seam
                                                            or edge of the
                                                            drapery.
                                                            <br />
                                                            <br />
                                                            <b>TRIM:</b>
                                                            &nbsp;Strips of
                                                            fabric or pre-made
                                                            trim applied on top
                                                            of the main fabric.
                                                            <br />
                                                            <br />
                                                        </TooltipText>
                                                    </TooltipContainer>
                                                }
                                                position="top left"
                                                className="normal"
                                                closeOnClick
                                            />
                                        </span>
                                    </MoreOptionsDivSectionSubHeader>
                                    <Field label="Type" colored>
                                        <SettingsColoredHalfLabel>
                                            Vertically
                                        </SettingsColoredHalfLabel>
                                        <HorizontalBorder />
                                        <SettingsColoredHalfLabel>
                                            Horizontally
                                        </SettingsColoredHalfLabel>
                                    </Field>
                                </>
                            )}
                            {this.props.data.embellishmentsList[2].checked ? (
                                <Field bordered>
                                    <span>Flange</span>
                                    <TooltipPopup
                                        text={
                                            <TooltipContainer>
                                                <TooltipText>
                                                    <b>Horizontal:</b>
                                                    &nbsp;QUIPA matches main
                                                    fabric side hem allowances
                                                    for this calculation.
                                                </TooltipText>
                                            </TooltipContainer>
                                        }
                                        position="top left"
                                        className="normal"
                                        closeOnClick
                                    />
                                    <HorizontalBorder
                                        style={{ marginLeft: "110px" }}
                                    />
                                    {/*this.props.data.flanges.some((f: any) => f.leadReturnEdge.active === true) ? (*/}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={{ marginRight: "-25px" }}>
                                            FL +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.flangeVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.flangeVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                    "draperyOrderDesign.customEmbellishmentJson.flangeVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            value={
                                                draperyOrderDesign
                                                    .customEmbellishmentJson
                                                    .flangeVertical !==
                                                    undefined
                                                    ? draperyOrderDesign
                                                        .customEmbellishmentJson
                                                        .flangeVertical
                                                    : ""
                                            }
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                    </div>
                                    {/*
                                    ) : (
                                        <div style={{ display: "flex", alignItems: "center", marginRight: "170px" }}>
                                        </div>
                                    )}
                                    */}
                                    <HorizontalBorder
                                        style={{ marginLeft: "49px" }}
                                    />
                                    {/*this.props.data.flanges.some(
                                        (f: any) => f.topEdge.active === true || f.bottomEdge.active === true
                                    ) ? (
                                    */}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span>
                                            FW +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.flangeHorizontal"
                                            name="draperyOrderDesign.customEmbellishmentJson.flangeHorizontal"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                    "draperyOrderDesign.customEmbellishmentJson.flangeHorizontal"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            // value={
                                            //     draperyOrderDesign && draperyOrderDesign.headingType === 'ripplefold' ? draperyOrderDesign?.customEmbellishmentJson?.flangeHorizontal || 0 :
                                            //         draperyOrderDesign
                                            //             .customEmbellishmentJson
                                            //             .flangeHorizontal !==
                                            //             undefined
                                            //             ? draperyOrderDesign
                                            //                 .customEmbellishmentJson
                                            //                 .flangeHorizontal * 2
                                            //             : draperyOrderDesign.customHEMSJson.sideHemFabricAllowance ? draperyOrderDesign.customHEMSJson.sideHemFabricAllowance * 2 : ""                                                    
                                            //         }
                                            value = {
                                                (draperyOrderDesign.customHEMSJson.sideHemFabricAllowance || 0) * 2
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={true}
                                            uiDisabled={true}
                                        />
                                    </div>
                                    {/*
                                    ) : (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        </div>
                                    )}
                                    */}
                                </Field>
                            ) : (
                                <></>
                            )}
                            {this.props.data.embellishmentsList[3].checked ? (
                                <Field bordered>
                                    <span style={{ minWidth: "156px" }}>
                                        Trim
                                    </span>
                                    <HorizontalBorder />
                                    {/*this.props.data.trims.some((t: any) => t.leadReturnEdge.active === true) ? (*/}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={{ marginRight: "-26px" }}>
                                            FL +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                    "draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                draperyOrderDesign
                                                    .customEmbellishmentJson
                                                    .trimVertical !== undefined
                                                    ? draperyOrderDesign
                                                        .customEmbellishmentJson
                                                        .trimVertical
                                                    : ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                    </div>
                                    {/*) : (
                                            <div style={{ display: "flex", alignItems: "center", marginRight: "170px" }}>
                                            </div>
                                        )}*/}
                                    <HorizontalBorder style={{ marginLeft: "50px" }} />
                                    {/*this.props.data.trims.some(
                                            (t: any) => t.topEdge.active === true || t.bottomEdge.active === true
                                        ) ? (*/}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <span>
                                            FW +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.trimHorizontal"
                                            name="draperyOrderDesign.customEmbellishmentJson.trimHorizontal"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                    "draperyOrderDesign.customEmbellishmentJson.trimHorizontal"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                draperyOrderDesign
                                                    .customEmbellishmentJson
                                                    .trimHorizontal !==
                                                    undefined
                                                    ? draperyOrderDesign
                                                        .customEmbellishmentJson
                                                        .trimHorizontal
                                                    : ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                    </div>
                                    {/*}) : (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        </div>
                                    )}*/}
                                </Field>
                            ) : (
                                <></>
                            )}
                            {this.props.data.embellishmentsList[1].checked ? (
                                <Field bordered>
                                    <span>Cording</span>
                                    <TooltipPopup
                                        text={
                                            <TooltipContainer>
                                                <TooltipText>
                                                    <b>Horizontal:</b>
                                                    &nbsp;QUIPA matches main
                                                    fabric side hem allowances
                                                    for this calculation.
                                                </TooltipText>
                                            </TooltipContainer>
                                        }
                                        position="top left"
                                        className="normal"
                                        closeOnClick
                                    />
                                    <HorizontalBorder
                                        style={{ marginLeft: "99px" }}
                                    />
                                    {/*this.props.data.cordings.some((c: any) => c.leadReturnEdge.active === true) ? (*/}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={{ marginRight: "-26px" }}>
                                            FL +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.cordingVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.cordingVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                    "draperyOrderDesign.customEmbellishmentJson.cordingVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                draperyOrderDesign
                                                    .customEmbellishmentJson
                                                    .cordingVertical !==
                                                    undefined
                                                    ? draperyOrderDesign
                                                        .customEmbellishmentJson
                                                        .cordingVertical
                                                    : ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                    </div>
                                    {/*) : (
                                    <div style={{ display: "flex", alignItems: "center", marginRight: "170px" }}>
                                    </div>
                                )}*/}
                                    <HorizontalBorder style={{ marginLeft: "51px" }} />
                                    {/*this.props.data.cordings.some(
                                    (c: any) => c.topEdge.active === true || c.bottomEdge.active === true
                                ) ? (*/}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <span>
                                            FW +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.cordingHorizontal"
                                            name="draperyOrderDesign.customEmbellishmentJson.cordingHorizontal"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                    "draperyOrderDesign.customEmbellishmentJson.cordingHorizontal"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                this.props.draperyOrderDesign.customEmbellishmentJson.cordingHorizontal !== undefined ? this.props.draperyOrderDesign.customEmbellishmentJson.cordingHorizontal :
                                                this.props.draperyOrderDesign.customHEMSJson.sideHemFabricAllowance !== undefined ? this.props.draperyOrderDesign.customHEMSJson.sideHemFabricAllowance * 2 :                          
                                                ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={true}
                                            uiDisabled={true}
                                        />
                                    </div>
                                    {/*       
                            ) : (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                </div>
                            )}
                            */}
                                </Field>
                            ) : (
                                <></>
                            )}
                            {this.props.data.embellishmentsList[0].checked ? (
                                <Field bordered>
                                    <span>Banding</span>
                                    <TooltipPopup
                                        text={
                                            <TooltipContainer>
                                                <TooltipText>
                                                    <b>Vertical:</b>
                                                    &nbsp;QUIPA matches the main
                                                    fabric cut length for this
                                                    calculation.
                                                    <br />
                                                    <br />
                                                    <b>Horizontal:</b>
                                                    &nbsp;QUIPA matches main
                                                    fabric side hem allowances
                                                    for this calculation.
                                                </TooltipText>
                                            </TooltipContainer>
                                        }
                                        position="top left"
                                        className="normal"
                                        closeOnClick
                                    />
                                    <HorizontalBorder
                                        style={{ marginLeft: "97px" }}
                                    />
                                    {/*this.props.data.bandings.some((b: any) => b.leadReturnEdge.active === true) ? (*/}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={{ marginRight: "-25px" }}>
                                            FL +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                    "draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                (this.props.draperyOrderDesign
                                                    .customHEMSJson
                                                    .topHemFabricAllowance ||
                                                    0) +
                                                (this.props.draperyOrderDesign
                                                    .customHEMSJson
                                                    .bottomHemFabricAllowance ||
                                                    0)
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={true}
                                            uiDisabled={true}
                                        />
                                    </div>
                                    {/*
                                    ) : (
                                        <div style={{ display: "flex", alignItems: "center", marginRight: "170px" }}>
                                        </div>
                                    )}
                                    */}
                                    <HorizontalBorder style={{ marginLeft: "50px" }} />
                                    {/*this.props.data.bandings.some(
                                        (b: any) => b.topEdge.active === true || b.bottomEdge.active === true
                                    ) ? (*/}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <span>
                                            FW +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                    "draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                this.props.draperyOrderDesign.customEmbellishmentJson.bandingHorizontal !== undefined ? this.props.draperyOrderDesign.customEmbellishmentJson.bandingHorizontal :
                                                this.props.draperyOrderDesign.customHEMSJson.sideHemFabricAllowance !== undefined ? this.props.draperyOrderDesign.customHEMSJson.sideHemFabricAllowance * 2 :
                                                ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={true}
                                            uiDisabled={true}
                                        />
                                    </div>
                                    {/*        
                            ) : (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                </div>
                            )}
                            */}
                                </Field>
                            ) : (
                                <></>
                            )}
                        </div>
                    </MoreOptionsDivSection>
                    <MoreOptionsDivSection style={{ marginBottom: "40px" }}>
                        <MoreOptionsDivSectionHeader>
                            MAIN FABRIC ADDITIONAL ALLOWANCE
                        </MoreOptionsDivSectionHeader>
                        <Field className="mt-5">
                            <SettingsCheckbox
                                id="draperyOrderDesign.customIsYardageAddOneExtra"
                                name="draperyOrderDesign.customIsYardageAddOneExtra"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customIsYardageAddOneExtra"
                                        ? highlightStyle
                                        : {}
                                }
                                checked={
                                    draperyOrderDesign.customIsYardageAddOneExtra
                                }
                                onChange={
                                    this.props.handleInputChangeForBoolean
                                }
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            >
                                Add one extra vertical repeat to MAIN FABRIC
                                total yardage (per work order) for placement
                                adjustment.
                            </SettingsCheckbox>
                        </Field>
                        <Field>
                            <SettingsCheckbox
                                id="draperyOrderDesign.customIsYardageAddExcess"
                                name="draperyOrderDesign.customIsYardageAddExcess"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customIsYardageAddExcess"
                                        ? highlightStyle
                                        : {}
                                }
                                checked={
                                    draperyOrderDesign.customIsYardageAddExcess
                                }
                                onChange={
                                    this.props.handleInputChangeForBoolean
                                }
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                                className="excess-box"
                            ></SettingsCheckbox>

                            <ExcessBox>
                                Add &nbsp;
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customAddExcessValue"
                                    name="draperyOrderDesign.customAddExcessValue"
                                    style={
                                        this.props.highlightedSection ===
                                            "draperyOrderDesign.customAddExcessValue"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customAddExcessValue !==
                                            undefined
                                            ? draperyOrderDesign.customAddExcessValue
                                            : ""
                                    }
                                    type="number"
                                    step={0.001}
                                    onChange={
                                        this.props.handleInputChangeForNumber
                                    }
                                    disabled={
                                        this.props.disabled ||
                                        !draperyOrderDesign.customIsYardageAddExcess
                                    }
                                    uiDisabled={
                                        this.props.disabled ||
                                        !draperyOrderDesign.customIsYardageAddExcess
                                    }
                                />
                                &nbsp;
                                <SelectList
                                    name="draperyOrderDesign.customAddExcessUnit"
                                    style={{
                                        border: "1px solid #545454",
                                        ...(this.props.highlightedSection ===
                                            "draperyOrderDesign.customAddExcessUnit"
                                            ? highlightStyle
                                            : {}),
                                    }}
                                    selectList={unitList}
                                    handleClickSelect={
                                        this.props.handleSelectListChange
                                    }
                                    valueSelect={
                                        draperyOrderDesign.customAddExcessUnit
                                    }
                                    placeholder
                                    placeholderText="Select Unit"
                                    flexDirection="row"
                                    smallPadding
                                    uiBorderColor="#3B97B1"
                                    disabled={
                                        this.props.disabled ||
                                        !draperyOrderDesign.customIsYardageAddExcess
                                    }
                                    noClearBox
                                    uiDropArrow
                                />
                                &nbsp; excess to MAIN FABRIC total yardage (per
                                work order)
                            </ExcessBox>
                        </Field>
                    </MoreOptionsDivSection>
                    <MoreOptionsDivSection>
                        <MoreOptionsDivSectionHeader
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            {`EDGE SIZES & FULLNESS`}
                            <TooltipWidePopup
                                text={
                                    <TooltipContainer>
                                        <TooltipSvgContainer>
                                            <TooltipReturnAndLeadEdge />
                                        </TooltipSvgContainer>
                                        <TooltipHeader>
                                            RETURN EDGE
                                        </TooltipHeader>
                                        <TooltipText>
                                            The horizontal space from the edge
                                            of the drapery that will return to
                                            the wall and the first pleat. The
                                            return edge size usually needs to
                                            match or be larger than the
                                            projection of the hardware bracket.
                                        </TooltipText>
                                        <TooltipHeader>LEAD EDGE</TooltipHeader>
                                        <TooltipText>
                                            The horizontal space between last
                                            pleat and the edge of the drapery
                                            that will be pulled to operate the
                                            drapery.
                                        </TooltipText>
                                        <TooltipSvgContainer>
                                            <TooltipOverlap />
                                        </TooltipSvgContainer>
                                        <TooltipHeader>OVERLAP</TooltipHeader>
                                        <TooltipText>
                                            The amount of horizontal space that
                                            a seamstress plans for two drapery
                                            panels to meet and cover the same
                                            area of rod in order to minimize
                                            light gap between them when hanging.
                                        </TooltipText>
                                        <DraperyFullnessTooltip>
                                            <TooltipHeader>
                                                DRAPERY FULLNESS
                                            </TooltipHeader>
                                            <TooltipText>
                                                The amount of extra fabric to be
                                                used in addition to finished
                                                width to create the desired body
                                                or “full” effect.&nbsp; The
                                                additional fabric is
                                                folded/gathered on the face of
                                                the panel to create a fuller
                                                look. For example 2x fullness
                                                indicates that the fabric pieces
                                                are twice as wide as the
                                                finished (pleated) width.
                                            </TooltipText>
                                            <div className="industry-standards">
                                                INDUSTRY STANDARDS
                                                <div className="industry-standards-table">
                                                    <div className="industry-standards-column">
                                                        <div>PLEATED</div>
                                                        <div>FLAT</div>
                                                        <div>SHEERS</div>
                                                    </div>
                                                    <div className="industry-standards-column border">
                                                        <div>
                                                            1.2-3x fullness
                                                        </div>
                                                        <div>1-2x fullness</div>
                                                        <div>3x fullness</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </DraperyFullnessTooltip>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal"
                                closeOnClick
                            />
                        </MoreOptionsDivSectionHeader>
                        <Field>
                            <HorizontalSpace />
                            <SettingsColoredHalfLabel
                                style={{ marginLeft: "220px" }}
                            >
                                Size
                            </SettingsColoredHalfLabel>
                        </Field>
                        <Field label="Lead / Butt" bordered>
                            <HorizontalBorder />
                            <SettingsLabeledInput
                                id="draperyOrderDesign.customReturnOverlapJson.leadSize"
                                name="draperyOrderDesign.customReturnOverlapJson.leadSize"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customReturnOverlapJson.leadSize"
                                        ? highlightStyle
                                        : {}
                                }
                                type="number"
                                value={
                                    draperyOrderDesign.customReturnOverlapJson
                                        .leadSize !== undefined
                                        ? draperyOrderDesign
                                            .customReturnOverlapJson.leadSize
                                        : ""
                                }
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                                label={window.innerWidth <= 992 ? "in." : "inches"}
                                // autoFocus
                                step={0.001}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                        </Field>
                        <Field label="Return" bordered>
                            <HorizontalBorder />
                            <SettingsLabeledInput
                                id="draperyOrderDesign.customReturnOverlapJson.returnSize"
                                name="draperyOrderDesign.customReturnOverlapJson.returnSize"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customReturnOverlapJson.returnSize"
                                        ? highlightStyle
                                        : {}
                                }
                                type="number"
                                value={
                                    draperyOrderDesign.customReturnOverlapJson
                                        .returnSize !== undefined
                                        ? draperyOrderDesign
                                            .customReturnOverlapJson
                                            .returnSize
                                        : ""
                                }
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                                label={window.innerWidth <= 992 ? "in." : "inches"}
                                // autoFocus
                                step={0.001}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                        </Field>
                        <Field label="Overlap" bordered>
                            <HorizontalBorder />
                            <SettingsLabeledInput
                                id="draperyOrderDesign.customReturnOverlapJson.overlapSize"
                                name="draperyOrderDesign.customReturnOverlapJson.overlapSize"
                                style={
                                    this.props.highlightedSection ===
                                        "draperyOrderDesign.customReturnOverlapJson.overlapSize"
                                        ? highlightStyle
                                        : {}
                                }
                                value={
                                    draperyOrderDesign.customReturnOverlapJson
                                        .overlapSize !== undefined
                                        ? draperyOrderDesign
                                            .customReturnOverlapJson
                                            .overlapSize
                                        : ""
                                }
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                                label={window.innerWidth <= 992 ? "in." : "inches"}
                                type="number"
                                step={0.001}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                        </Field>
                        {this.renderFourthQuestion()}
                    </MoreOptionsDivSection>
                </MoreOptionsDiv>
            </WorkroomFormWrapper>
        );
    }
    private renderFourthQuestion = (): JSX.Element => {
        const { formErrorID, draperyOrderDesign, highlightedSection } =
            this.props;
        return (
            <CalculatorFormField
                id="4"
                uiInline
                uiNoControlWrap
                uiNoMargin
                isError={
                    highlightedSection === "draperyOrderDesign.draperyFullness"
                }
                draperyFullness="calculator"
                style={{ marginTop: '0px', paddingTop: '0px' }}
            >
                <CalculatorFormFieldHeader>
                    {/* <CalculatorFormQuestionNumber></CalculatorFormQuestionNumber> */}
                    <CalculatorFormFieldLabel
                        uiError={formErrorID === "4"}
                        draperyFullness="calculator"
                        style={{ fontSize: '16px', marginLeft: '11px' }}
                    >
                        Fullness
                    </CalculatorFormFieldLabel>
                    <TooltipPopup
                        text={
                            <TooltipContainer>
                                <DraperyFullnessTooltip>
                                    <TooltipHeader>
                                        DRAPERY FULLNESS
                                    </TooltipHeader>
                                    <TooltipText>
                                        The amount of extra fabric to be used in
                                        addition to finished width to create the
                                        desired body or “full” effect.&nbsp; The
                                        additional fabric is folded/gathered on
                                        the face of the panel to create a fuller
                                        look. For example 2x fullness indicates
                                        that the fabric pieces are twice as wide
                                        as the finished (pleated) width.
                                    </TooltipText>
                                    <div className="industry-standards">
                                        INDUSTRY STANDARDS
                                        <div className="industry-standards-table">
                                            <div className="industry-standards-column">
                                                <div>PLEATED</div>
                                                <div>FLAT</div>
                                                <div>SHEERS</div>
                                            </div>
                                            <div className="industry-standards-column border">
                                                <div>1.5-3x fullness</div>
                                                <div>1-2x fullness</div>
                                                <div>3x fullness</div>
                                            </div>
                                        </div>
                                    </div>
                                </DraperyFullnessTooltip>
                            </TooltipContainer>
                        }
                        position="top left"
                        className="normal"
                        closeOnClick
                    />
                    <HorizontalBorder style={{ marginLeft: window.innerWidth <= 992 ? "87px" : "129px" }} />
                    <FullnessField
                        className="h-14"
                        uiDisabled={this.props.disabled}
                    >
                        {/* QUIPA-1360 code START */}
                        <div className="h-full flex items-center justify-center py-2 px-6">
                            {draperyOrderDesign.headingType === 'ripplefold' ? (
                                <SelectFullness
                                    className="w-full border rounded text-sm"
                                    name="draperyOrderDesign.draperyFullness"
                                    onChange={
                                        this.props.handleFullnessSelect
                                    }
                                    value={draperyOrderDesign.draperyFullness}
                                    disabled={this.props.disabled}
                                >
                                    <option value="0">No Default</option>
                                    <option value="0.6">60%</option>
                                    <option value="0.8">80%</option>
                                    <option value="1">100%</option>
                                    <option value="1.2">120%</option>
                                </SelectFullness>
                            ) : (
                                <SelectFullness
                                    className="w-full border rounded text-sm"
                                    name="draperyOrderDesign.draperyFullness"
                                    onChange={
                                        this.props.handleFullnessSelect
                                    }
                                    value={draperyOrderDesign.draperyFullness}
                                    disabled={this.props.disabled}
                                >

                                    <option value="0">No Default</option>
                                    <option value="1">1x Fullness</option>
                                    <option value="1.5">1.5x Fullness</option>
                                    <option value="2">2x Fullness</option>
                                    <option value="2.5">2.5x Fullness</option>
                                    <option value="3">3x Fullness</option>
                                </SelectFullness>
                            )}
                        </div>
                    </FullnessField>
                </CalculatorFormFieldHeader>
            </CalculatorFormField>
        );
    };
}

export default DrapperyOrderWorkroomSettings;
