import { Checkbox, Input, InputProps } from "@ramble/ramble-ui";
import {
    TooltipContainer,
    TooltipHeader,
    TooltipPopup,
    TooltipText,
    TooltipWidePopup,
    TooltipImgContainer,
} from "./styles";

import TooltipBackOfDrapery from "../../assets/images/tooltip/back-of-drapery.png";
import TooltipHemSideView from "../../assets/images/tooltip/hem-side-view.png";

import SettingsIcon from '../../assets/icons/icons8-settings.svg'

import React, {
    ChangeEvent,
    FC,
    forwardRef,
    PureComponent,
    ReactNode,
} from "react";
import SelectListE from "../../components/UI/select-list";
import { DraperyModule } from "../../redux";
import styled, { css } from "../../theme";
import { highlightStyle } from "./calculator";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";

const unitList = ["yard(s)", "%"];

const ExcessBox = styled.span`
    line-height: 35px;
    font-size: 18px;
    width: 80%;
    padding-left: 0.2rem;
`;

const WorkroomFormWrapper = styled.div`
    width: 100%;
    background-color: #fff;
`;

const MoreOptionsDiv = styled.div`
    margin: 5px auto 0 auto;
    max-width: 900px;
    min-width: 570px;
    @media (min-width: 1120px) and (max-width: 1678px) {
        width: 70%;
    }
    @media (min-width: 768px) and (max-width: 1120px) {
        width: 90%;
    }
    @media (max-width: 768px) {
        width: 100%;
    }

    & .edit_construction_container{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & .edit_construction_content{
            border: 1px solid ${({ theme }) => theme.colors.primary};
            border-radius: 6px;
            gap: 5px;
            padding: 7px 10px 7px 7px;
            cursor: pointer;

            & .construction_icon{
                transform: rotate(0deg);
                transition: all 1s ease;
            }
            &:hover{
                & .construction_icon{
                    transform: rotate(180deg);
                }
            }


            &>p{
                color: ${({ theme }) => theme.colors.primary};
                font-weight: 600;
            }

            &.disabled {
                border-color: #d4d4d4;

                &>p{
                    color: #d4d4d4;
                }
                & .construction_icon{
                    opacity: 0.4;
                }
            }

        }
    }
`;

const MoreOptionsDivSection = styled.div`
    background-color: inherit;
    border: 1px solid #dadada;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 14px;

    &>:last-child {
        border-bottom: none;
    }
`;

const MoreOptionsDivSectionHeader = styled.div`
    background-color: #d6eaf0;
    color: #3b97b1;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 14px 0px 14px 19px;

    hr {
        width: 80%;
        margin: 5px 0 0 0;
        border-left: none;
        border-right: none;
        border-bottom: none;
    }
`;

const SettingsCheckbox = styled(Checkbox)`
    width: 80%;
    align-items: start;
    color: #545454;
    font-family: Poppins;
    font-size: 18px;
    margin-bottom: auto;

    > span.label {
        border: 0 !important;
        line-height: 35px;
    }

    > svg {
        margin-top: 8px;
    }
    ${(props) =>
    props.className === "excess-box" &&
    css`
            width: 3%;
        `}
`;

interface FieldProps {
    /**
     * Field label
     */
    label?: string;
    colored?: boolean;
    bordered?: boolean;
    /**
     * True to render as subfield with margin
     */
    subField?: boolean;
    children: ReactNode;
    className?: string;
    id?: string;
    /**
     * Label prop
     */
    title?: boolean;
}

const FieldE: FC<FieldProps> = (props) => (
    <div style={ props.id === "top-hem-div" ? { position: "relative" } : {}} className={props.className}>
        {props.label && <div id={props.id || undefined} className="field-label">{props.label}</div>}
        {props.children}
    </div>
);

/**
 * Single field in options
 */
const Field = styled(FieldE)`
    display: flex;
    margin: 0 0 0 20px;
    align-items: center;
    color: #545454;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${(props) =>
    props.bordered &&
    css`
            border-bottom: 1px solid #e6e6e6;
            width: 95%;
        `}
    > .field-label {
        margin-right: 5px;
        display: inline-block;
        min-width: 180px;
        @media screen and (max-width: 992px) {
            min-width:140px;
        }
        ${(props) =>
    props.colored &&
    css`
                color: #306f82;
                margin-right: 86px;
            `}
    }

    ${(props) =>
    props.label === "" &&
    css`
            padding-left: 90px;
        `}

    ${(props) =>
    props.subField &&
    css`
            display: inline-block;
            margin: 5px 0 0 150px;
        `};

    ${(props) =>
    props.title === true &&
    css`
            color: #3b97b1;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-top: 16px;
            margin-right: 120px;
            text-align: right;
            display: unset;
        `}
`;

const InputLabel = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
`;

const LabeledInputE = forwardRef<
    HTMLInputElement,
    InputProps & { label?: string } & { orderType?: string } & {additionText?: string} & {defaultMargin?: boolean}
>(({ label, ...props }, ref) => (
    <>
        {props.additionText ? (
            <span className="mr-2">{props.additionText} +</span>
        ) : null}
        <Input {...props} orderType={props.orderType || undefined} ref={ref} />
        {label && <InputLabel style={props.id === "draperyOrderDesign.customHemsAndFoldsJson.topHemFinishedSize" && props.orderType === "roman-shade" ? { visibility: "hidden" } : {}}>{label}</InputLabel>}
    </>
));

const SettingsLabeledInput = styled(LabeledInputE)`
    width: 59px;
    height: 32px;
    border: solid #545454 1px;
    padding: 0.4em 0.6em !important;
    line-height: 16px;
    margin-left: 24px;
    @media screen and (max-width: 992px) {
        margin-left:0px;
        }
`;

const HorizontalBorder = styled.div`
    height: 60px;
    margin: 0 40px;
    border-left: 1px #e6e6e6 solid;
`;

const SelectList = styled(SelectListE)`
    width: 140px;
    line-height: 16px;
    display: inline-block;
`;
interface FormContainerProps {
    uiDisabled?: boolean;
}

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 2fr 2fr;
    margin-bottom: 10px;
    align-items: center;
    justify-items: center;
`

interface TableRowProps {
    noBottomBorder?: boolean;
}
const TableRow = styled.div<TableRowProps>`
    display: grid;
    grid-template-columns: 1.5fr 2fr 2fr;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px #e6e6e6 solid;

    ${props => props.noBottomBorder && `border-bottom: none;`}
`
interface TableCellProps {
    noRightBorder?: boolean;
    leftAlign?: boolean;
    gap?: number;
}
const TableCell = styled.div<TableCellProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px #e6e6e6 solid;
    height: 59px;
    margin-right: 1.25rem;

    ${props => props.noRightBorder && `border-right: none;`}
    ${props => props.leftAlign && `justify-content: flex-start;`}
    ${props => props.gap !== undefined && props.gap >= 0 && `gap: ${props.gap}px;`}
`

export interface WorkroomSettingsProps {
    draperyOrderDesign: DraperyModule.DraperyOrderDesign;
    disabled: boolean;
    highlightedSection: string | null | undefined;
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleOnBlurCustomCutLengthJsonLinings?(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSelectListChange(value: string | number, name?: string): void;
    handleBuckramSelect(e: ChangeEvent<HTMLSelectElement>): void;
    handleFullnessSelect(e: ChangeEvent<HTMLSelectElement>): void;
    formErrorID?: string;
    embellishmentsList: any[];
    data?: any;
    workroomSetting: WorkroomSetting | null;
    openConstructionDetailsEditModal?(): void;
}

// tslint:disable-next-line:no-empty-interface
interface State { }

export class DrapperyOrderWorkroomSettings extends PureComponent<
    WorkroomSettingsProps,
    State,
    FormContainerProps
> {
    public constructor(props: WorkroomSettingsProps) {
        super(props);
        this.state = {
            embellishmentsList: this.props.embellishmentsList || [],
        };
    }

    public handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (
            e.keyCode === 69 ||
            e.key === "e" ||
            e.keyCode === 188 ||
            e.keyCode === 187 ||
            e.keyCode === 61 ||
            e.keyCode === 107
        ) {
            e.preventDefault();
        }
       if (
           e.currentTarget.valueAsNumber &&
           (e.keyCode === 189 || e.keyCode === 173 || e.keyCode === 109)
       ) {
           e.preventDefault();
       }
       if (
           e.currentTarget.valueAsNumber.toString().includes("0") && e.key === '-'
       ) {
           e.preventDefault();
       }
       if (
           e.currentTarget.validity.badInput &&
           (e.keyCode === 189 || e.keyCode === 173 || e.keyCode === 109)
       ) {
           e.preventDefault();
       }
       if (
           e.currentTarget.value.includes("-") &&
           (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
       ) {
           e.preventDefault();
       }
    }

    public render(): JSX.Element {
        const { draperyOrderDesign } = this.props;
        return (
            <WorkroomFormWrapper>
                <MoreOptionsDiv>
                    <div className="edit_construction_container">
                        <div onClick={() => {
                            if (!this.props.disabled) {
                                this.props.openConstructionDetailsEditModal && this.props.openConstructionDetailsEditModal()
                            }
                        }} className={`flex items-center justify-center edit_construction_content ${this.props.disabled ? 'disabled' : ''}`}>
                            <SettingsIcon className="construction_icon" style={{ width: '25px', height: '25px' }} />
                            <p>Edit Settings</p>
                        </div>
                    </div>
                    <MoreOptionsDivSection
                        id="more-options-div"
                        style={{ marginTop: "20px", marginBottom: "40px" }}
                    >
                        <MoreOptionsDivSectionHeader
                            style={{ display: "flex", alignItems: "center", position: "relative" }}
                        >
                            HEMS
                            <TooltipWidePopup
                                text={
                                    <TooltipContainer>
                                        <TooltipHeader>HEM SIZE</TooltipHeader>
                                        <TooltipText>
                                            The finished size of a hem.
                                        </TooltipText>
                                        <TooltipImgContainer>
                                            <img
                                                src={TooltipBackOfDrapery}
                                                alt="Tooltip"
                                            />
                                        </TooltipImgContainer>
                                        <TooltipHeader>
                                            HEM ALLOWANCE
                                        </TooltipHeader>
                                        <TooltipText>
                                            The total amount of fabric needed to
                                            create a hem including the folded
                                            fabric.
                                            <br />
                                            <br />
                                            Hem Allowance = Hem Size + Fold
                                            <br />
                                            <br />
                                            For example, if the top hem size is
                                            4” and I use a double turned hem, I
                                            need an 8” allowance of fabric to
                                            create the folded hem. 4” Hem size +
                                            4” Fold = 8” hem allowance.
                                        </TooltipText>
                                        <TooltipImgContainer>
                                            <img
                                                src={TooltipHemSideView}
                                                alt="Tooltip"
                                            />
                                        </TooltipImgContainer>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal"
                                closeOnClick
                            />
                        </MoreOptionsDivSectionHeader>

                        <TableHeader>
                            <div></div>

                            <div className="h-[40px] flex items-center">
                                <span>Finished Size</span>
                            </div>
                            
                            <div className="h-[40px] flex items-center">
                                <span>Total Allowance</span>
                            </div>
                        </TableHeader>
                        
                        <TableRow>
                            <TableCell leftAlign>
                                <span>Top</span>
                            </TableCell>

                            <TableCell>
                                <span>N/A</span>
                            </TableCell>

                            <TableCell noRightBorder>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customHemsAndFoldsJson.topHemFabricAllowance"
                                    name="draperyOrderDesign.customHemsAndFoldsJson.topHemFabricAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customHemsAndFoldsJson.topHemFabricAllowance"
                                            ? highlightStyle
                                            : {}
                                    }
                                    type="number"
                                    value={
                                        draperyOrderDesign.customHemsAndFoldsJson
                                            .topHemFabricAllowance !== undefined
                                            ? draperyOrderDesign.customHemsAndFoldsJson
                                                .topHemFabricAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    // autoFocus
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                />
                            </TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell leftAlign>
                                <span>Bottom no Skirt</span>
                            </TableCell>

                            <TableCell>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customHemsAndFoldsJson.bottomNoSkirtHemFinishedSize"
                                    name="draperyOrderDesign.customHemsAndFoldsJson.bottomNoSkirtHemFinishedSize"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customHemsAndFoldsJson.bottomNoSkirtHemFinishedSize"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customHemsAndFoldsJson
                                            .bottomNoSkirtHemFinishedSize !== undefined
                                            ? draperyOrderDesign.customHemsAndFoldsJson
                                                .bottomNoSkirtHemFinishedSize
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    type="number"
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                />
                            </TableCell>

                            <TableCell noRightBorder>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customHemsAndFoldsJson.bottomNoSkirtHemAllowance"
                                    name="draperyOrderDesign.customHemsAndFoldsJson.bottomNoSkirtHemAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customHemsAndFoldsJson.bottomNoSkirtHemAllowance"
                                            ? highlightStyle
                                            : {}
                                    }
                                    type="number"
                                    value={
                                        draperyOrderDesign.customHemsAndFoldsJson
                                            .bottomNoSkirtHemAllowance !== undefined
                                            ? draperyOrderDesign.customHemsAndFoldsJson
                                                .bottomNoSkirtHemAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    // autoFocus
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell leftAlign>
                                <span>Bottom with Skirt</span>
                                <TooltipPopup
                                    text={
                                        <TooltipContainer>
                                            <TooltipText>
                                            <span className="font-bold">Bottom skirt</span> is also commonly referred to as the bottom flap, reveal, or footer. It refers to moving the rings up in position on the back of the shade to allow the bottom of the shade material to be exposed beneath the folds when it is lifted.
                                            </TooltipText>
                                        </TooltipContainer>
                                    }
                                    position="top left"
                                    className="normal"
                                    closeOnClick
                                />
                            </TableCell>

                            <TableCell>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customHemsAndFoldsJson.bottomWithSkirtHemFinishedSize"
                                    name="draperyOrderDesign.customHemsAndFoldsJson.bottomWithSkirtHemFinishedSize"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customHemsAndFoldsJson.bottomWithSkirtHemFinishedSize"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customHemsAndFoldsJson
                                            .bottomWithSkirtHemFinishedSize !== undefined
                                            ? draperyOrderDesign.customHemsAndFoldsJson
                                                .bottomWithSkirtHemFinishedSize
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    type="number"
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                />
                            </TableCell>

                            <TableCell noRightBorder>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customHemsAndFoldsJson.bottomWithSkirtHemAllowance"
                                    name="draperyOrderDesign.customHemsAndFoldsJson.bottomWithSkirtHemAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customHemsAndFoldsJson.bottomWithSkirtHemAllowance"
                                            ? highlightStyle
                                            : {}
                                    }
                                    type="number"
                                    value={
                                        draperyOrderDesign.customHemsAndFoldsJson
                                            .bottomWithSkirtHemAllowance !== undefined
                                            ? draperyOrderDesign.customHemsAndFoldsJson
                                                .bottomWithSkirtHemAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    // autoFocus
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow noBottomBorder>
                            <TableCell leftAlign>
                                <span>Side Hems</span>
                            </TableCell>

                            <TableCell>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customHemsAndFoldsJson.sideHemFinishedSize"
                                    name="draperyOrderDesign.customHemsAndFoldsJson.sideHemFinishedSize"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customHemsAndFoldsJson.sideHemFinishedSize"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customHemsAndFoldsJson
                                            .sideHemFinishedSize !== undefined
                                            ? draperyOrderDesign.customHemsAndFoldsJson
                                                .sideHemFinishedSize
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    type="number"
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                />
                            </TableCell>

                            <TableCell noRightBorder>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customHemsAndFoldsJson.sideHemFabricAllowance"
                                    name="draperyOrderDesign.customHemsAndFoldsJson.sideHemFabricAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customHemsAndFoldsJson.sideHemFabricAllowance"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customHemsAndFoldsJson
                                            .sideHemFabricAllowance !== undefined
                                            ? draperyOrderDesign.customHemsAndFoldsJson
                                                .sideHemFabricAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    type="number"
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                />
                            </TableCell>
                        </TableRow>
                    </MoreOptionsDivSection>
                    <MoreOptionsDivSection style={{ marginBottom: "40px" }}>
                        <MoreOptionsDivSectionHeader
                            style={{ display: "flex", alignItems: "center"}}
                        >
                            VALANCE & RETURNS
                        </MoreOptionsDivSectionHeader>

                        <TableHeader>
                            <div></div>
                            <div className="h-[40px] flex items-center">
                                <span>Vertical Allowance</span>
                            </div>
                            
                            <div className="h-[40px] flex items-center">
                                <span>Horizontal Allowance</span>
                            </div>
                        </TableHeader>
                        
                        <TableRow>
                            <TableCell leftAlign>
                                <span>Front Valance</span>
                            </TableCell>

                            <TableCell>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customValanceAndReturnsJson.frontValanceVerticalAllowance"
                                    name="draperyOrderDesign.customValanceAndReturnsJson.frontValanceVerticalAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customValanceAndReturnsJson.presetHemsAndFoldsJson"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customValanceAndReturnsJson
                                            .frontValanceVerticalAllowance !== undefined
                                            ? draperyOrderDesign.customValanceAndReturnsJson
                                                .frontValanceVerticalAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    additionText="(Valance FL x 2)"
                                    type="number"
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                    defaultMargin
                                />
                            </TableCell>

                            <TableCell noRightBorder>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customValanceAndReturnsJson.frontValanceHorizontalAllowance"
                                    name="draperyOrderDesign.customValanceAndReturnsJson.frontValanceHorizontalAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customValanceAndReturnsJson.frontValanceHorizontalAllowance"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customValanceAndReturnsJson
                                            .frontValanceHorizontalAllowance !== undefined
                                            ? draperyOrderDesign.customValanceAndReturnsJson
                                                .frontValanceHorizontalAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    additionText="Valance FW"
                                    type="number"
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                    defaultMargin
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell leftAlign>
                                <span>Back Valance</span>
                            </TableCell>

                            <TableCell>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customValanceAndReturnsJson.backValanceVerticalAllowance"
                                    name="draperyOrderDesign.customValanceAndReturnsJson.backValanceVerticalAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customValanceAndReturnsJson.backValanceVerticalAllowance"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customValanceAndReturnsJson
                                            .backValanceVerticalAllowance !== undefined
                                            ? draperyOrderDesign.customValanceAndReturnsJson
                                                .backValanceVerticalAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    additionText="(Valance FL x 2)"
                                    type="number"
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                    defaultMargin
                                />
                            </TableCell>

                            <TableCell noRightBorder>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customValanceAndReturnsJson.backValanceHorizontalAllowance"
                                    name="draperyOrderDesign.customValanceAndReturnsJson.backValanceHorizontalAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customValanceAndReturnsJson.backValanceHorizontalAllowance"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customValanceAndReturnsJson
                                            .backValanceHorizontalAllowance !== undefined
                                            ? draperyOrderDesign.customValanceAndReturnsJson
                                                .backValanceHorizontalAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    additionText="Valance FW"
                                    type="number"
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                    defaultMargin
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow noBottomBorder>
                            <TableCell leftAlign>
                                <span>Side Flaps</span>
                            </TableCell>

                            <TableCell>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customValanceAndReturnsJson.sideFlapsVerticalAllowance"
                                    name="draperyOrderDesign.customValanceAndReturnsJson.sideFlapsVerticalAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customValanceAndReturnsJson.sideFlapsVerticalAllowance"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customValanceAndReturnsJson
                                            .sideFlapsVerticalAllowance !== undefined
                                            ? draperyOrderDesign.customValanceAndReturnsJson
                                                .sideFlapsVerticalAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    additionText="Side Flaps FL"
                                    type="number"
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                    defaultMargin
                                />
                            </TableCell>

                            <TableCell noRightBorder>
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customValanceAndReturnsJson.sideFlapsHorizontalAllowance"
                                    name="draperyOrderDesign.customValanceAndReturnsJson.sideFlapsHorizontalAllowance"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customValanceAndReturnsJson.sideFlapsHorizontalAllowance"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customValanceAndReturnsJson
                                            .sideFlapsHorizontalAllowance !== undefined
                                            ? draperyOrderDesign.customValanceAndReturnsJson
                                                .sideFlapsHorizontalAllowance
                                            : ""
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    label={window.innerWidth <= 992 ? "in." : "in."}
                                    additionText="Side Flaps FW"
                                    type="number"
                                    step={0.001}
                                    onChange={this.props.handleInputChangeForNumber}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                    defaultMargin
                                />
                            </TableCell>
                        </TableRow>
                    </MoreOptionsDivSection>
                    <MoreOptionsDivSection style={{ marginBottom: "40px" }}>
                        <MoreOptionsDivSectionHeader>
                            CUT LENGTHS
                        </MoreOptionsDivSectionHeader>

                        <TableHeader>
                            <div></div>
                            <div className="h-[40px] flex items-center">
                                <span>Vertical Allowance</span>
                            </div>
                            
                            <div className="h-[40px] flex items-center">
                                <span>Horizontal Allowance</span>
                            </div>
                        </TableHeader>

                        <TableRow>
                            <TableCell leftAlign>
                                <span>Main Fabric</span>
                            </TableCell>

                            <TableCell gap={5}>
                                <span>FL +</span>
                                <span>x</span>
                                <span>in.</span>
                            </TableCell>

                            <TableCell noRightBorder gap={5}>
                                <span>FL +</span>
                                <span>{draperyOrderDesign && draperyOrderDesign.customHemsAndFoldsJson && draperyOrderDesign.customHemsAndFoldsJson.sideHemFabricAllowance !== undefined ? +draperyOrderDesign.customHemsAndFoldsJson.sideHemFabricAllowance * 2 : 0}</span>
                                <span>in.</span>
                            </TableCell>
                        </TableRow>
                        {this.props.data.liningsList.some(
                            (lining: { name: string; active: boolean }) =>
                                lining.name === "Lining" && lining.active
                        ) ? (
                            <TableRow>
                                <TableCell leftAlign>
                                    <span>Lining</span>
                                </TableCell>

                                <TableCell>
                                    <span>FL +</span>
                                    <SettingsLabeledInput
                                        id="draperyOrderDesign.customLiningsJson.liningVerticalAllowance"
                                        name="draperyOrderDesign.customLiningsJson.liningVerticalAllowance"
                                        style={
                                            this.props.highlightedSection ===
                                            "draperyOrderDesign.customLiningsJson.liningVerticalAllowance"
                                                ? highlightStyle
                                                : {}
                                        }
                                        value={
                                            draperyOrderDesign
                                                .customLiningsJson.liningVerticalAllowance !==
                                            undefined
                                                ? draperyOrderDesign
                                                    .customLiningsJson
                                                    .liningVerticalAllowance
                                                : ""
                                        }
                                        onKeyDown={(evt) => this.handleKeyDown(evt)}
                                        onPaste={(evt: any) => evt.preventDefault()}
                                        label={'in.'}
                                        type="number"
                                        step={0.001}
                                        onChange={
                                            this.props
                                                .handleInputChangeForNumber
                                        }
                                        disabled={this.props.disabled}
                                        uiDisabled={this.props.disabled}
                                    />
                                </TableCell>

                                <TableCell noRightBorder>
                                    <span>N/A</span>
                                </TableCell>
                            </TableRow>
                        ) : null}

                        {this.props.data.liningsList.some(
                            (lining: { name: string; active: boolean }) =>
                                lining.name === "Interlining" && lining.active
                        ) ? (
                            <TableRow>
                                <TableCell leftAlign>
                                    <span>Interlining</span>
                                </TableCell>

                                <TableCell>
                                    <span>FL +</span>
                                    <SettingsLabeledInput
                                        id="draperyOrderDesign.customLiningsJson.interliningVerticalAllowance"
                                        name="draperyOrderDesign.customLiningsJson.interliningVerticalAllowance"
                                        style={
                                            this.props.highlightedSection ===
                                            "draperyOrderDesign.customLiningsJson.interliningVerticalAllowance"
                                                ? highlightStyle
                                                : {}
                                        }
                                        value={
                                            draperyOrderDesign
                                                .customLiningsJson
                                                .interliningVerticalAllowance !== undefined
                                                ? draperyOrderDesign
                                                    .customLiningsJson
                                                    .interliningVerticalAllowance
                                                : ""
                                        }
                                        onKeyDown={(evt) => this.handleKeyDown(evt)}
                                        onPaste={(evt: any) => evt.preventDefault()}
                                        label={'in.'}
                                        type="number"
                                        step={0.001}
                                        onChange={
                                            this.props
                                                .handleInputChangeForNumber
                                        }
                                        onBlur={(e) => {
                                            if (this.props.handleOnBlurCustomCutLengthJsonLinings && e.target.value) {
                                                this.props.handleOnBlurCustomCutLengthJsonLinings(e)
                                            }
                                        }}
                                        disabled={this.props.disabled}
                                        uiDisabled={this.props.disabled}
                                    />
                                </TableCell>

                                <TableCell noRightBorder>
                                    <span>N/A</span>
                                </TableCell>
                            </TableRow>
                        ) : null}

                        {/* <div> */}
                            {this.props.data.embellishmentsList[2].checked ? (
                                <Field bordered>
                                    <span>Flange</span>
                                    <TooltipPopup
                                        text={
                                            <TooltipContainer>
                                                <TooltipText>
                                                    <b>Horizontal:</b>
                                                    &nbsp;QUIPA matches main
                                                    fabric side hem allowances
                                                    for this calculation.
                                                </TooltipText>
                                            </TooltipContainer>
                                        }
                                        position="top left"
                                        className="normal"
                                        closeOnClick
                                    />
                                    <HorizontalBorder
                                        style={{ marginLeft: "110px" }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={{ marginRight: "-25px" }}>
                                            FL +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.flangeVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.flangeVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                "draperyOrderDesign.customEmbellishmentJson.flangeVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            value={
                                                draperyOrderDesign
                                                    .customEmbellishmentJson
                                                    .flangeVertical !==
                                                undefined
                                                    ? draperyOrderDesign
                                                        .customEmbellishmentJson
                                                        .flangeVertical
                                                    : ""
                                            }
                                            label={'in.'}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                    </div>
                                    <HorizontalBorder
                                        style={{ marginLeft: "49px" }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span>
                                            FW +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.flangeHorizontal"
                                            name="draperyOrderDesign.customEmbellishmentJson.flangeHorizontal"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                "draperyOrderDesign.customEmbellishmentJson.flangeHorizontal"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value = {
                                                (draperyOrderDesign.customHEMSJson.sideHemFabricAllowance || 0) * 2
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={'in.'}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={true}
                                            uiDisabled={true}
                                        />
                                    </div>
                                </Field>
                            ) : (
                                null
                            )}
                            {this.props.data.embellishmentsList[3].checked ? (
                                <TableRow>
                                    <TableCell leftAlign>
                                        <span>Trim</span>
                                    </TableCell>

                                    <TableCell>
                                        <span>FL +</span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                "draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                draperyOrderDesign
                                                    .customEmbellishmentJson
                                                    .trimVertical !== undefined
                                                    ? draperyOrderDesign
                                                        .customEmbellishmentJson
                                                        .trimVertical
                                                    : ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={'in.'}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                    </TableCell>

                                    <TableCell noRightBorder>
                                        <span>FW +</span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.trimHorizontal"
                                            name="draperyOrderDesign.customEmbellishmentJson.trimHorizontal"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                "draperyOrderDesign.customEmbellishmentJson.trimHorizontal"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                draperyOrderDesign
                                                    .customEmbellishmentJson
                                                    .trimHorizontal !==
                                                undefined
                                                    ? draperyOrderDesign
                                                        .customEmbellishmentJson
                                                        .trimHorizontal
                                                    : ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={'in.'}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                null
                            )}
                            {this.props.data.embellishmentsList[1].checked ? (
                                <Field bordered>
                                    <span>Cording</span>
                                    <TooltipPopup
                                        text={
                                            <TooltipContainer>
                                                <TooltipText>
                                                    <b>Horizontal:</b>
                                                    &nbsp;QUIPA matches main
                                                    fabric side hem allowances
                                                    for this calculation.
                                                </TooltipText>
                                            </TooltipContainer>
                                        }
                                        position="top left"
                                        className="normal"
                                        closeOnClick
                                    />
                                    <HorizontalBorder
                                        style={{ marginLeft: "99px" }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={{ marginRight: "-26px" }}>
                                            FL +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.cordingVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.cordingVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                "draperyOrderDesign.customEmbellishmentJson.cordingVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                draperyOrderDesign
                                                    .customEmbellishmentJson
                                                    .cordingVertical !==
                                                undefined
                                                    ? draperyOrderDesign
                                                        .customEmbellishmentJson
                                                        .cordingVertical
                                                    : ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                    </div>
                                    <HorizontalBorder style={{ marginLeft: "51px" }} />
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <span>
                                            FW +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.cordingHorizontal"
                                            name="draperyOrderDesign.customEmbellishmentJson.cordingHorizontal"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                "draperyOrderDesign.customEmbellishmentJson.cordingHorizontal"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                this.props.draperyOrderDesign.customEmbellishmentJson.cordingHorizontal !== undefined ? this.props.draperyOrderDesign.customEmbellishmentJson.cordingHorizontal :
                                                    this.props.draperyOrderDesign.customHEMSJson.sideHemFabricAllowance !== undefined ? this.props.draperyOrderDesign.customHEMSJson.sideHemFabricAllowance * 2 :
                                                        ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={true}
                                            uiDisabled={true}
                                        />
                                    </div>
                                </Field>
                            ) : (
                                null
                            )}
                            {this.props.data.embellishmentsList[0].checked ? (
                                <Field bordered>
                                    <span>Banding</span>
                                    <TooltipPopup
                                        text={
                                            <TooltipContainer>
                                                <TooltipText>
                                                    <b>Vertical:</b>
                                                    &nbsp;QUIPA matches the main
                                                    fabric cut length for this
                                                    calculation.
                                                    <br />
                                                    <br />
                                                    <b>Horizontal:</b>
                                                    &nbsp;QUIPA matches main
                                                    fabric side hem allowances
                                                    for this calculation.
                                                </TooltipText>
                                            </TooltipContainer>
                                        }
                                        position="top left"
                                        className="normal"
                                        closeOnClick
                                    />
                                    <HorizontalBorder
                                        style={{ marginLeft: "97px" }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={{ marginRight: "-25px" }}>
                                            FL +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                "draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                (this.props.draperyOrderDesign
                                                        .customHEMSJson
                                                        .topHemFabricAllowance ||
                                                    0) +
                                                (this.props.draperyOrderDesign
                                                        .customHEMSJson
                                                        .bottomHemFabricAllowance ||
                                                    0)
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={true}
                                            uiDisabled={true}
                                        />
                                    </div>
                                    <HorizontalBorder style={{ marginLeft: "50px" }} />
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <span>
                                            FW +
                                        </span>
                                        <SettingsLabeledInput
                                            id="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            name="draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                            style={
                                                this.props
                                                    .highlightedSection ===
                                                "draperyOrderDesign.customEmbellishmentJson.trimVertical"
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            value={
                                                this.props.draperyOrderDesign.customEmbellishmentJson.bandingHorizontal !== undefined ? this.props.draperyOrderDesign.customEmbellishmentJson.bandingHorizontal :
                                                    this.props.draperyOrderDesign.customHEMSJson.sideHemFabricAllowance !== undefined ? this.props.draperyOrderDesign.customHEMSJson.sideHemFabricAllowance * 2 :
                                                        ""
                                            }
                                            onKeyDown={(evt) => {
                                                const key = evt.key;
                                                const isNumericKey = /\d/.test(key);
                                                const isDotKey = key === ".";
                                                const isBackspaceKey = key === "Backspace";
                                                if (
                                                    !isNumericKey &&
                                                    !isDotKey &&
                                                    !isBackspaceKey
                                                ) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            label={window.innerWidth <= 992 ? "in." : "inches"}
                                            type="number"
                                            step={0.001}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={true}
                                            uiDisabled={true}
                                        />
                                    </div>
                                </Field>
                            ) : (
                                null
                            )}
                        {/* </div> */}
                    </MoreOptionsDivSection>
                    <MoreOptionsDivSection style={{ marginBottom: "40px" }}>
                        <MoreOptionsDivSectionHeader>
                            MAIN FABRIC ADDITIONAL ALLOWANCE
                        </MoreOptionsDivSectionHeader>
                        <Field className="mt-5">
                            <SettingsCheckbox
                                id="draperyOrderDesign.customIsYardageAddOneExtra"
                                name="draperyOrderDesign.customIsYardageAddOneExtra"
                                style={
                                    this.props.highlightedSection ===
                                    "draperyOrderDesign.customIsYardageAddOneExtra"
                                        ? highlightStyle
                                        : {}
                                }
                                checked={
                                    draperyOrderDesign.customIsYardageAddOneExtra
                                }
                                onChange={
                                    this.props.handleInputChangeForBoolean
                                }
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            >
                                Add one extra vertical repeat to MAIN FABRIC
                                total yardage (per work order) for placement
                                adjustment.
                            </SettingsCheckbox>
                        </Field>
                        <Field>
                            <SettingsCheckbox
                                id="draperyOrderDesign.customIsYardageAddExcess"
                                name="draperyOrderDesign.customIsYardageAddExcess"
                                style={
                                    this.props.highlightedSection ===
                                    "draperyOrderDesign.customIsYardageAddExcess"
                                        ? highlightStyle
                                        : {}
                                }
                                checked={
                                    draperyOrderDesign.customIsYardageAddExcess
                                }
                                onChange={
                                    this.props.handleInputChangeForBoolean
                                }
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                                className="excess-box"
                            ></SettingsCheckbox>

                            <ExcessBox>
                                Add &nbsp;
                                <SettingsLabeledInput
                                    id="draperyOrderDesign.customAddExcessValue"
                                    name="draperyOrderDesign.customAddExcessValue"
                                    style={
                                        this.props.highlightedSection ===
                                        "draperyOrderDesign.customAddExcessValue"
                                            ? highlightStyle
                                            : {}
                                    }
                                    value={
                                        draperyOrderDesign.customAddExcessValue !==
                                        undefined
                                            ? draperyOrderDesign.customAddExcessValue
                                            : ""
                                    }
                                    type="number"
                                    step={0.001}
                                    onChange={
                                        this.props.handleInputChangeForNumber
                                    }
                                    onKeyDown={(evt) => {
                                        const key = evt.key;
                                        const isNumericKey = /\d/.test(key);
                                        const isDotKey = key === ".";
                                        const isBackspaceKey = key === "Backspace";
                                        if (
                                            !isNumericKey &&
                                            !isDotKey &&
                                            !isBackspaceKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    disabled={
                                        this.props.disabled ||
                                        !draperyOrderDesign.customIsYardageAddExcess
                                    }
                                    uiDisabled={
                                        this.props.disabled ||
                                        !draperyOrderDesign.customIsYardageAddExcess
                                    }
                                />
                                &nbsp;
                                <SelectList
                                    name="draperyOrderDesign.customAddExcessUnit"
                                    style={{
                                        border: "1px solid #545454",
                                        ...(this.props.highlightedSection ===
                                        "draperyOrderDesign.customAddExcessUnit"
                                            ? highlightStyle
                                            : {}),
                                    }}
                                    selectList={unitList}
                                    handleClickSelect={
                                        this.props.handleSelectListChange
                                    }
                                    valueSelect={
                                        draperyOrderDesign.customAddExcessUnit
                                    }
                                    placeholder
                                    placeholderText="Select Unit"
                                    flexDirection="row"
                                    smallPadding
                                    uiBorderColor="#3B97B1"
                                    disabled={
                                        this.props.disabled ||
                                        !draperyOrderDesign.customIsYardageAddExcess
                                    }
                                    noClearBox
                                    uiDropArrow
                                />
                                &nbsp; excess to MAIN FABRIC total yardage (per
                                work order)
                            </ExcessBox>
                        </Field>
                    </MoreOptionsDivSection>
                </MoreOptionsDiv>
            </WorkroomFormWrapper>
        );
    }
}

export default DrapperyOrderWorkroomSettings;
