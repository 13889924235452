import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";
interface InchesInputProps {
    finishedCutId: string;
    CutAllowanceId: string;
    finishedCutValue?: string | number;
    cutAllowanceValue?: string | number;
    onChange: (params: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isBottom?: boolean;
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    finishedSizePlaceholder?: string | undefined; 
    cutAllowancePlaceholder?: string | undefined;  
}

const HemsInput = styled.input`
    ::placeholder {
        color: #C2C2C2; 
    }
    :focus-within {
        outline: none;
        border: 1px solid #3b97b1 !important;
    }
    padding-left: 6px;
`;

const InchesInput: FC<InchesInputProps> = ({
    finishedCutId,
    CutAllowanceId,
    finishedCutValue,
    cutAllowanceValue,
    onChange,
    handleKeyDown,
    isBottom = false,
    className = "",
    onFocus,
    finishedSizePlaceholder, 
    cutAllowancePlaceholder
}) => {
    return (
        <div
            className={`grid grid-cols-2 divide-x divide-[#E2E2E2] ${
                isBottom ? "" : "border-b border-[#E2E2E2]"
            } h-14`}
        >
            {/* Finished Size */}
            <div className="flex flex-row items-center justify-center p-0 h-14">
                <HemsInput
                    id={finishedCutId}
                    name={finishedCutId}
                    value={finishedCutValue}
                    className="mr-2 rounded-[8px] items-center border max-w-[50%] lg:max-w-[72px]  text-[16px] px-2 py-[10px] h-9 font-normal border-[#AAAAAA]"
                    type="number"
                    step={0.001}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    onFocus={onFocus}
                    placeholder={finishedSizePlaceholder} 
                />
                <p className="text-[#222222] font-normal leading">in.</p>
            </div>

            {/* Cut Allowance */}
            <div className="flex flex-row items-center justify-center p-4 h-14">
                <HemsInput
                    id={CutAllowanceId}
                    name={CutAllowanceId}
                    value={cutAllowanceValue}
                    className="mr-2 rounded-[8px] items-center border max-w-[50%] lg:max-w-[72px]  px-2 py-[10px] h-9 font-normal border-[#AAAAAA]"
                    onChange={onChange}
                    type="number"
                    onKeyDown={handleKeyDown}
                    onFocus={onFocus}
                    placeholder={cutAllowancePlaceholder} 
                />
                <p className="text-[#222222] font-normal leading">in.</p>
            </div>
        </div>
    );
};

export default InchesInput;
