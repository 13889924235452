import {
    Button,
    Checkbox,
    FormValidationError,
    Modal,
    ModalActions,
    ModalHeader,
    PageDimmer,
    PopoverScrollContext,
    ValidationForm,
} from "@ramble/ramble-ui";
import { CategoryIds, ItemClasses } from "../types";
// import { ConfirmationCloseModal } from "../../../modals/confirmation_close_modal";
import {
    FabricsOrLiningsItemDetailsFieldsPartial,
    fabricsOrLiningsItemDetailsFieldsInitialState,
    fabricsOrLiningsItemDetailsFieldsReducer, FabricsOrLiningsItemDetailsFields,
} from "../../../redux/modules/my-shop/fabrics-or-linings-item-details-fields-reducer";
import {
    FormFieldsPartial,
    formFieldsInitialState,
    formFieldsReducer
} from "../../../redux/modules/my-shop/form-fields-reducer";
import { ItemModalContent, ItemTabs, TabContainer } from "../styles";
import React, {
    FC,
    FormEvent,
    KeyboardEvent,
    useEffect,
    useReducer,
    useState,
} from "react";
import { Tab, TabList } from "react-tabs";
import {
    addFabricsOrLiningsRecord,
    addRecord,
    getFabricsOrLiningsRecord,
    getRecord,
    updateFabricsOrLiningsRecord,
    updateRecord,
} from "../../../api/item";
import styled from "../../../theme";

import { Category } from "../../../interfaces/category";
import Details from "./details";
import Pricing from "./pricing";
import Specifications from "./specifications";
import { Supplier } from "../../../interfaces/supplier";
import { detect } from "detect-browser";
import { getSelectedName } from "../helpers";
import { DraperyOrderFabric } from "../../calculator/calculator";
import { Client } from "../../../redux/modules/client";
import _ from "lodash";
import { confirmation } from "../../../confirmation";
import { Prompt } from "react-router-dom";

const CancelButton = styled(Button)`
    background-color: #f6f6f6;
    border-radius: 0.5em;
    border: 1px solid #cdcdcd;
    color: #222222;

    &:hover {
        background-color: #eeeeee;
    }
`;

const ModalItem = styled(Modal)`
    width: 90%;
    min-height: 300px;
    bottom: 0px;
`;

const ValidationFormItem = styled(ValidationForm)`
    min-height: inherit !important;
`;

interface StandardItemModalProps {
    isModalOpen: boolean;
    categories: Category[];
    suppliers: Supplier[];
    itemId: number | null;
    activeItemClassId: number | null;
    tabIndex?: number;
    onModalClose(): void;
    reloadGrid?(): void;
    openModalClick?(): void;
    highlightedSection?: string | null;
    itemName?: string | null;
    validateAll?(mode: string): boolean;
    mainFabric?: DraperyOrderFabric;
    indexOfMainFabric?: number;
    handleCloseModal?(): void;
    resetSupplier?(): void;
    categoryId?: number;
    updateStateByName?(
        name: string,
        value: string | number | boolean | number[] | undefined | object,
        isSaving?: boolean
    ): void;
    pathIdForModal?: string | null;
    pathNameForModal?: string;
    fabricPatternModalCategoryId?: number;

    clearHighlightSection?(name: string): void;
    highLightSection?(name: string): void;
    client?: Client;
    supplierName?: string;
    setSupplierName?: (name: string) => void;
    supplierId?: number | null;
    updateFabricsTab?(payload: any): void;
    fromCalculator?: boolean;
    draperyOrderFabricsErrors?: any[]
}

interface ScrollInfo {
    scrollX: number;
    scrollY: number;
}

const StandardItemModal: FC<StandardItemModalProps> = ({
    tabIndex,
    isModalOpen,
    categories,
    suppliers,
    itemId,
    activeItemClassId,
    onModalClose,
    reloadGrid,
    openModalClick,
    highlightedSection,
    validateAll,
    mainFabric,
    updateStateByName,
    indexOfMainFabric,
    categoryId,
    pathIdForModal,
    pathNameForModal,
    fabricPatternModalCategoryId,
    handleCloseModal,
    clearHighlightSection,
    highLightSection,
    client,
    supplierName,
    setSupplierName,
    supplierId,
    resetSupplier,
    updateFabricsTab,
    fromCalculator,
    draperyOrderFabricsErrors
}) => {
    const browser = detect();
    const inactiveItemError = draperyOrderFabricsErrors
    ? draperyOrderFabricsErrors.some((error) => error.name === pathNameForModal)
    : false;

        // const [isCloseModalActive, setIsCloseModalActive] =
    //     useState<boolean>(false);
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const [isCategory, setIsCategory] = useState(false);
    const [isCategoryErrorMessage, setIsCategoryErrorMessage] = useState(false);
    const [isName, setIsName] = useState(false);
    const [isNameMessageError, setIsNameMessageError] = useState<boolean>(false)
    const [areaScrollInfo, setAreaScrollInfo] = useState<ScrollInfo>({
        scrollX: 0,
        scrollY: 0,
    });
    const [formFields, dispatch] = useReducer(
        formFieldsReducer,
        formFieldsInitialState
    );
    const [copyFormFields, copyDispatch] = useReducer(formFieldsReducer, formFieldsInitialState)
    const [formValues, setFormValues] = useState<FormFieldsPartial>(formFields);
    const [isUpdateChanged, setIsUpdateChanged] = useState<boolean>(false);
    const [fabricsOrLiningsItemDetailsFields, dispatchItemDetails] = useReducer(
        fabricsOrLiningsItemDetailsFieldsReducer,
        fabricsOrLiningsItemDetailsFieldsInitialState
    );
    let isFabricsOrLiningsCategory: boolean = false;
    const [errorElementId, setErrorElementId] = useState<string | null>(null);
    const [categoryNameNew, setCategoryName] = useState("");
    const [validationError, setValidationError] = useState<boolean>(false)

    if (formFields.parentCategoryId || formFields.categoryId) {
        isFabricsOrLiningsCategory = [
            CategoryIds.FabricsAndTrims,
            CategoryIds.Linings,
        ].includes(formFields.parentCategoryId || formFields.categoryId);
    }

    useEffect(() => {
        if (tabIndex !== undefined) {
            setActiveTabIndex(tabIndex);
        }
    }, [tabIndex]);

    useEffect(() => {
        setFormValues((prevState) => ({
            ...prevState,
            salesDescription: formFields.salesDescription,
            orderingDescription: formFields.orderingDescription,
        }));
    }, [formFields.name,
        formFields.fabricsOrLiningsItemDetails && formFields.fabricsOrLiningsItemDetails.fabricWidthInches,
        formFields.fabricsOrLiningsItemDetails && formFields.fabricsOrLiningsItemDetails.verticalRepeatInches,
        formFields.fabricsOrLiningsItemDetails && formFields.fabricsOrLiningsItemDetails.horizontalRepeatInches,
        formFields.fabricsOrLiningsItemDetails && formFields.fabricsOrLiningsItemDetails.isDropRepeatOn,
        formFields.fabricsOrLiningsItemDetails && formFields.fabricsOrLiningsItemDetails.isSpecialtyFabricHandlingOn
    ]);

    useEffect(() => {
        if(formValues.id) {
            let hasChanged = false;
            for(let key1 in formValues){
                for (let key2 in formFields){
                    if(key1 === key2 && !_.isEqual(formValues[key1], formFields[key1])){
                        hasChanged = true;
                        break;
                    }
                }
            }
            setIsUpdateChanged(hasChanged);
        }
    }, [formValues, formFields])

    if (mainFabric && mainFabric.values) {
        fabricsOrLiningsItemDetailsFieldsInitialState.fabricWidthInches =
            String(mainFabric.values.fabricWidth);
        fabricsOrLiningsItemDetailsFieldsInitialState.horizontalRepeatInches =
            String(mainFabric.values.horizontalRepeat);
        fabricsOrLiningsItemDetailsFieldsInitialState.verticalRepeatInches =
            String(mainFabric.values.verticalRepeat);
    }

    const handleInvalidElem = (elem: HTMLElement): void => {
        const elemId = elem.id;

        if (
            [
                "name",
                "itemCategory",
                "itemTypeProduct",
                "itemTypeService",
            ].includes(elemId)
        ) {
            setValidationError(prev => !prev)
            setIsNameMessageError(true)
            setActiveTabIndex(0);
            if (!formFields.categoryId) {
                setIsCategoryErrorMessage(true)
                setIsCategory(true)
            }
        } else {
            setIsNameMessageError(false)
            setActiveTabIndex(1);
        }

        setErrorElementId(elemId);
    };

    const handleSubmitForm = async (
        e: FormEvent<HTMLFormElement>
    ): Promise<void> => {
        e.preventDefault();
        const {
            name,
            itemNumber,
            avatarId,
            itemTypeId,
            supplierId,
            categoryId,
            notes,
            isActive,
            cost,
            msrp,
            retailPricingMethod,
            retailPricingValue,
            tradePricingMethod,
            tradePricingValue,
            shippingCost,
            handlingFeeMethod,
            handlingFeeValue,
        } = formFields;

        if (!categoryId) {
            setActiveTabIndex(0);
            setIsCategory(true)
            setIsCategoryErrorMessage(true)
            return
        }
        if (!name) {
            setActiveTabIndex(0);
            setIsNameMessageError(true)
            setIsName(true)
            return
        }

        const salesTextArea = document.getElementById(
            "salesDescription"
        ) as HTMLInputElement;

        const orderingTextArea = document.getElementById(
            "orderingDescription"
        ) as HTMLInputElement;

        let payload: FormFieldsPartial = {
            name,
            itemNumber,
            avatarId,
            itemTypeId,
            supplierId,
            categoryId,
            notes,
            isActive,
            cost,
            msrp,
            retailPricingMethod,
            retailPricingValue,
            tradePricingMethod,
            tradePricingValue,
            shippingCost,
            handlingFeeMethod,
            handlingFeeValue,
        };

        for (let key in payload) {
            if (
                isFabricsOrLiningsCategory &&
                updateStateByName &&
                [
                    "cost",
                    "msrp",
                    "retailPricingMethod",
                    "retailPricingValue",
                    "tradePricingMethod",
                    "tradePricingValue",
                    "handlingFeeMethod",
                    "handlingFeeValue",
                    "shippingCost",
                ].includes(key) &&
                indexOfMainFabric !== undefined &&
                indexOfMainFabric >= 0
            ) {
                if (formFields.categoryId === 101) {
                    updateStateByName(
                        `fabricsPatternItems.${indexOfMainFabric}.${key}`,
                        payload[key]
                    );
                } else if (formFields.categoryId === 110) {
                    updateStateByName(
                        `liningsPatternItems.${indexOfMainFabric}.${key}`,
                        payload[key]
                    );
                }
            }
        }
        if (salesTextArea) {
            payload.salesDescription = salesTextArea.value;
        }

        if (orderingTextArea) {
            payload.orderingDescription = orderingTextArea.value;
        }

        if (isFabricsOrLiningsCategory) {
            const {
                fabricWidthInches,
                verticalRepeatInches,
                horizontalRepeatInches,
                isDropRepeatOn,
                isMatchRepeatAtSeamsOn,
                isPleatToPatternOn,
                directionOfUseTypeId,
                isSpecialtyFabricHandlingOn,
            } = fabricsOrLiningsItemDetailsFields;

            let detailsPayload: FabricsOrLiningsItemDetailsFieldsPartial = {
                fabricWidthInches,
                verticalRepeatInches,
                horizontalRepeatInches,
                isDropRepeatOn,
                isMatchRepeatAtSeamsOn,
                isPleatToPatternOn,
                directionOfUseTypeId,
                isSpecialtyFabricHandlingOn,
            };

            payload = {
                ...payload,
                ...detailsPayload,
            };
        }
        const pricingArr = [
            { tradePricingMethod: "tradePricingValue" },
            { retailPricingMethod: "retailPricingValue" },
            { cost: "cost" },
            { msrp: "msrp" },
        ];

        for (const price of pricingArr) {
            for (let [key, value] of Object.entries(price)) {
                if (
                    payload[key] === false ||
                    payload[key] === undefined ||
                    payload[key] === ""
                ) {
                    payload[key] = null;
                    //   payload[value] = null;
                    payload[value as string] = null;
                }
            }
        }

        let result: any;
        if (itemId) {
            payload = {
                ...payload,
                id: itemId,
                itemId,
            };
            result = isFabricsOrLiningsCategory
                ? await updateFabricsOrLiningsRecord(payload)
                : await updateRecord(payload);
        } else {
            result = isFabricsOrLiningsCategory
                ? await addFabricsOrLiningsRecord(payload)
                : await addRecord(payload);
        }

        const errors = result.data.errors;
        const status = result.status;
        if (errors) {
            setAreaScrollInfo({
                scrollX: 0,
                scrollY: 0,
            });
            throw FormValidationError.fromErrorDictionary(errors);
        } else if ([201, 200].includes(status)) {
            if (reloadGrid) await reloadGrid();
            if(updateFabricsTab) await updateFabricsTab(payload);
            onSaveClose();
        }
        if (setSupplierName) setSupplierName("");
        if (handleCloseModal) handleCloseModal();
        if (resetSupplier) resetSupplier();
    };

    const handleKeyPressForm = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            e.preventDefault();
        }
    };

    const selectTab = (index: number) => {
        setActiveTabIndex(index);
        setAreaScrollInfo({
            scrollX: 0,
            scrollY: 0,
        });
    };

    const toggleInactive = () => {
        dispatch({
            type: "updateFormFields",
            formFieldUpdates: { isActive: !formFields.isActive },
        });
    };

    const deepEqual = (obj1: any, obj2: any): boolean => {
        if (obj1 === obj2) return true;

        if (obj1 == null || obj2 == null) return false;

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
        }

        return true;
    };

    const showEditWorkOrderConfirm = (): Promise<boolean> => {
        return confirmation(<div>Would you like to cancel your edits?</div>, {
            uiHeader: "Confirmation",
            uiAcceptLabel: "No, continue editing",
            uiDeclineLabel: "Yes, cancel edits",
            workOrderEditMode: true,
            cancelEditing: () => {
                onModalClose();
            },
        });
    };

    const onClose = () => {
        if (deepEqual(formFields, copyFormFields)) {
            // setIsCloseModalActive(false);
            dispatch({ type: 'resetFormFields' })
            setActiveTabIndex(0)
            onModalClose()
        } else if (fromCalculator && isUpdateChanged || !fromCalculator) {   
            // setIsCloseModalActive(true);
            showEditWorkOrderConfirm();
        } else if (fromCalculator && !isUpdateChanged) {
            dispatch({ type: "resetFormFields" });
            setActiveTabIndex(0);
            onModalClose();
            if (setSupplierName) setSupplierName("");
        }
    };

    const onSaveClose = () => {
        dispatch({ type: "resetFormFields" });
        setActiveTabIndex(0);
        onModalClose();
        if (setSupplierName) setSupplierName("");
    };

    const handleNextChange = () => {
        if (activeTabIndex < 2) {
            setActiveTabIndex(activeTabIndex! + 1);
        }
    };

    const renderDetails = (): JSX.Element => {
        return (
            <TabContainer>
                <Details
                    openModalClick={openModalClick}
                    categories={categories}
                    suppliers={suppliers}
                    isCategory={isCategory}
                    isCategoryErrorMessage={isCategoryErrorMessage}
                    setIsCategory={setIsCategory}
                    setIsCategoryErrorMessage={setIsCategoryErrorMessage}
                    isName={isName}
                    setIsName={setIsName}
                    browser={browser}
                    formFields={formFields}
                    fabricsOrLiningsItemDetailsFields={
                        fabricsOrLiningsItemDetailsFields
                    }
                    dispatch={dispatch}
                    pathIdForModal={pathIdForModal}
                    pathNameForModal={pathNameForModal}
                    fabricPatternModalCategoryId={fabricPatternModalCategoryId}
                    supplierName={supplierName}
                    supplierId={supplierId}
                    categoryName={categoryNameNew}
                    validationError={validationError}
                    isNameMessageError={isNameMessageError}
                    setIsNameMessageError={setIsNameMessageError}
                />
            </TabContainer>
        );
    };

    const renderPricing = (): JSX.Element => {
        return (
            <TabContainer>
                <Pricing
                    highlightedSection={highlightedSection}
                    browser={browser}
                    formFields={formFields}
                    dispatch={dispatch}
                    updateStateByName={updateStateByName}
                    indexOfMainFabric={indexOfMainFabric}
                    clearHighlightSection={clearHighlightSection}
                    highLightSection={highLightSection}
                    client={client}
                />
            </TabContainer>
        );
    };

    const renderSpecifications = (): JSX.Element => {
        return (
            <TabContainer>
                <Specifications
                    browser={browser}
                    fabricsOrLiningsItemDetailsFields={
                        fabricsOrLiningsItemDetailsFields
                    }
                    dispatch={dispatchItemDetails}
                    dispatchTwo={dispatch}
                    formFields={formFields}
                    activeItemClassId={activeItemClassId}
                    itemId={itemId}
                />
            </TabContainer>
        );
    };

    const getModalTitle = (): JSX.Element | "" => {
        const { categoryName, name } = formFields;

        if (!categoryName && !name) {
            return (
                <span className="text-twPrimary font-medium">Add New Item</span>
            );
        }

        return (
            <div>
                {categoryName && (
                    <>
                        <span className="text-twBlue">{categoryName}</span>
                        <span className="p-1">|</span>
                    </>
                )}
                <span className="text-twBlue">{`${name || ""}`}</span>
            </div>
        );
    };

    useEffect(() => {
        if (errorElementId) {
            const errorElement = document.getElementById(errorElementId);
            if (errorElement) {
                errorElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
                errorElement.focus();
            }
        }
    }, [errorElementId]);

    useEffect(() => {
        if (itemId) {
            if (activeItemClassId === ItemClasses.FabricsOrLining) {
                getFabricsOrLiningsRecord({ recordId: itemId })
                    .then((res) => {
                        const itemUpdates: FormFieldsPartial = {
                            ...res,
                        };

                        const itemDetailsUpdates: FabricsOrLiningsItemDetailsFieldsPartial =
                            {
                                ...itemUpdates,
                            };

                        const categoryName = getSelectedName({
                            list: categories as [],
                            findByKey: "id",
                            findValue: itemUpdates.categoryId,
                            nameField: "name",
                        });

                        const supplierName = getSelectedName({
                            list: suppliers as [],
                            findByKey: "id",
                            findValue: itemUpdates.supplierId,
                            nameField: "displayName",
                        });
                        const getSubCategory = categories.find(
                            (cat) => cat.id === itemUpdates.categoryId
                        );
                        const idCat =
                            (getSubCategory &&
                                getSubCategory.parentCategoryId &&
                                getSubCategory.parentCategoryId) ||
                            0;

                        dispatch({
                            type: "updateFormFields",
                            formFieldUpdates: {
                                ...itemUpdates,
                                categoryName,
                                supplierName,
                                parentCategoryId: idCat,
                            },
                        });
                        copyDispatch({
                            type: "updateFormFields",
                            formFieldUpdates: {
                                ...itemUpdates,
                                categoryName,
                                supplierName,
                                parentCategoryId: idCat,
                            },
                        });
                        dispatchItemDetails({
                            type: "updateFormFields",
                            formFieldUpdates: {
                                ...itemDetailsUpdates,
                                fabricWidthInches:
                                    itemDetailsUpdates.fabricWidthInches !==
                                    undefined
                                        ? itemDetailsUpdates.fabricWidthInches
                                        : "0",
                                verticalRepeatInches:
                                    itemDetailsUpdates.verticalRepeatInches !==
                                    undefined
                                        ? itemDetailsUpdates.verticalRepeatInches
                                        : "0",
                                horizontalRepeatInches:
                                    itemDetailsUpdates.horizontalRepeatInches !==
                                    undefined
                                        ? itemDetailsUpdates.horizontalRepeatInches
                                        : "0",
                            },
                        });
                        const updateObj = {
                            ...itemUpdates,
                            categoryName,
                            supplierName,
                            parentCategoryId: idCat,
                        }

                        if (itemUpdates.fabricsOrLiningsItemDetails) {
                            const itemDetailsUpdates: FabricsOrLiningsItemDetailsFieldsPartial =
                                {
                                    ...itemUpdates.fabricsOrLiningsItemDetails,
                                };

                            updateObj.fabricsOrLiningsItemDetails = {
                                ...itemDetailsUpdates as FabricsOrLiningsItemDetailsFields,
                                fabricWidthInches:
                                    itemDetailsUpdates.fabricWidthInches ||
                                    "0",
                                verticalRepeatInches:
                                    itemDetailsUpdates.verticalRepeatInches ||
                                    "0",
                                horizontalRepeatInches:
                                    itemDetailsUpdates.horizontalRepeatInches ||
                                    "0",
                            }
                        }

                        setFormValues((prev) => ({
                            ...prev,
                            ...updateObj,
                        }))
                    })
                    .catch((err) => {
                        console.log(
                            `error fetching fabrics or linings item: ${err}`
                        );
                    });
            } else {
                getRecord({ recordId: itemId })
                    .then((res) => {
                        const itemUpdates: FormFieldsPartial = {
                            ...res,
                        };
                        const getSubCategory = categories.find(
                            (cat) => cat.id === itemUpdates.categoryId
                        );
                        const idCat =
                            (getSubCategory &&
                                getSubCategory.parentCategoryId &&
                                getSubCategory.parentCategoryId) ||
                            0;
                        let itemDetailsUpdates:  FabricsOrLiningsItemDetailsFieldsPartial = {};

                        if (itemUpdates.fabricsOrLiningsItemDetails) {
                            itemDetailsUpdates =
                                {
                                    ...itemUpdates.fabricsOrLiningsItemDetails,
                                };

                            dispatchItemDetails({
                                type: "updateFormFields",
                                formFieldUpdates: {
                                    ...itemDetailsUpdates,
                                    fabricWidthInches:
                                        itemDetailsUpdates.fabricWidthInches ||
                                        undefined,
                                    verticalRepeatInches:
                                        itemDetailsUpdates.verticalRepeatInches ||
                                        undefined,
                                    horizontalRepeatInches:
                                        itemDetailsUpdates.horizontalRepeatInches ||
                                        undefined,
                                },
                            });
                        }

                        const categoryName = getSelectedName({
                            list: categories as [],
                            findByKey: "id",
                            findValue: itemUpdates.categoryId,
                            nameField: "name",
                        });

                        const supplierName = getSelectedName({
                            list: suppliers as [],
                            findByKey: "id",
                            findValue: itemUpdates.supplierId,
                            nameField: "displayName",
                        });

                        dispatch({
                            type: "updateFormFields",
                            formFieldUpdates: {
                                ...itemUpdates,
                                fabricsOrLiningsItemDetails: {
                                    ...itemDetailsUpdates as FabricsOrLiningsItemDetailsFields,
                                    fabricWidthInches:
                                        itemDetailsUpdates.fabricWidthInches ||
                                        "0",
                                    verticalRepeatInches:
                                        itemDetailsUpdates.verticalRepeatInches ||
                                        "0",
                                    horizontalRepeatInches:
                                        itemDetailsUpdates.horizontalRepeatInches ||
                                        "0",
                                },
                                categoryName,
                                supplierName,
                                parentCategoryId: idCat
                            },
                        });
                        copyDispatch({
                            type: "updateFormFields",
                            formFieldUpdates: {
                                ...itemUpdates,
                                fabricsOrLiningsItemDetails: {
                                    ...itemDetailsUpdates as FabricsOrLiningsItemDetailsFields,
                                   fabricWidthInches:
                                       itemDetailsUpdates.fabricWidthInches ||
                                       "0",
                                   verticalRepeatInches:
                                       itemDetailsUpdates.verticalRepeatInches ||
                                       "0",
                                   horizontalRepeatInches:
                                       itemDetailsUpdates.horizontalRepeatInches ||
                                       "0",
                              },
                                categoryName,
                                supplierName,
                                parentCategoryId: idCat
                            },
                        });
                        const updateObj = {
                            ...itemUpdates,
                            categoryName,
                            supplierName,
                        }
                        if (itemUpdates.fabricsOrLiningsItemDetails) {
                            const itemDetailsUpdates: FabricsOrLiningsItemDetailsFieldsPartial =
                                {
                                    ...itemUpdates.fabricsOrLiningsItemDetails,
                                };
                            updateObj.fabricsOrLiningsItemDetails = {
                                ...itemDetailsUpdates as FabricsOrLiningsItemDetailsFields,
                                fabricWidthInches:
                                    itemDetailsUpdates.fabricWidthInches ||
                                    "0",
                                verticalRepeatInches:
                                    itemDetailsUpdates.verticalRepeatInches ||
                                    "0",
                                horizontalRepeatInches:
                                    itemDetailsUpdates.horizontalRepeatInches ||
                                    "0",
                            }
                        }
                        if(itemUpdates.fabricsOrLiningsItemDetails) {
                            setFormValues((prev) => ({
                                ...prev,
                               ...updateObj
                            }))
                        }
                    })
                    .catch((err) => {
                        console.log(`error fetching drapery item: ${err}`);
                    });
            }
        }
    }, [itemId, activeItemClassId]);
    useEffect(() => {
        const html = document.querySelector("html")!;
        if (isModalOpen) {
            html.style.position = "fixed";
            html.style.width = "100%";
        } else {
            html.style.position = "static";
            html.style.width = "initial";
        }
    }, [isModalOpen]);
    useEffect(() => {
        setCategoryName(formFields.categoryName);
    }, [formFields.categoryName]);

    return (
        <>
            <Prompt
                message={(location: any): string | boolean => {
                    if (isModalOpen) {
                        showEditWorkOrderConfirm();
                        return false;
                    } else {
                        window.location.replace('/myshop')
                        return true
                    }
                }}
            />
            <PageDimmer uiActive={isModalOpen} />
            <ModalItem
                uiActive={isModalOpen}
                uiOnRequestClose={() => {
                    return;
                }}
                uiDefaultSizes={false}
            >
                <ValidationFormItem
                    id="validation-form"
                    uiOnSubmit={handleSubmitForm}
                    uiReportValidityMode="firstInvalid"
                    uiOnInvalidElem={handleInvalidElem}
                    onKeyPress={handleKeyPressForm}
                    uiPadding={false}
                    className="h-full"
                >
                    <ModalHeader className="border-0">
                        <div className="flex justify-between w-full pb-2 border-b-8 border-twSecondary">
                            {getModalTitle()}
                            <Checkbox
                                checked={!formFields.isActive}
                                onClick={toggleInactive}
                                className={`font-normal text-base ${inactiveItemError && !formFields.isActive ? 'uiError' : ''}`}
                            >
                                Make Inactive
                            </Checkbox>
                        </div>
                    </ModalHeader>

                    <ItemModalContent className="mb-8">
                        <PopoverScrollContext.Provider value={areaScrollInfo}>
                            <ItemTabs
                                selectedIndex={activeTabIndex}
                                onSelect={selectTab}
                                forceRenderTabPanel
                                className="flex flex-col flex-1 h-full"
                            >
                                <TabList>
                                    <Tab>Details</Tab>
                                    <Tab>Pricing</Tab>
                                    {isFabricsOrLiningsCategory && (
                                        <Tab>Specifications</Tab>
                                    )}
                                </TabList>
                                {renderDetails()}
                                {renderPricing()}
                                {isFabricsOrLiningsCategory &&
                                    renderSpecifications()}
                            </ItemTabs>
                        </PopoverScrollContext.Provider>
                    </ItemModalContent>
                    {((activeTabIndex > 1 && isFabricsOrLiningsCategory) ||
                        (activeTabIndex > 0 &&
                            !isFabricsOrLiningsCategory)) && (
                        <ModalActions>
                            <CancelButton
                                type="button"
                                uiColor="secondary"
                                onClick={onClose}
                                className="rounded-lg mr-auto block shadow-none"
                            >
                                Cancel
                            </CancelButton>

                            <Button
                                className="rounded-lg border border-twBlue2 block shadow-none"
                                uiColor="primary"
                            >
                                Save
                            </Button>
                        </ModalActions>
                    )}
                    {((activeTabIndex < 2 && isFabricsOrLiningsCategory) ||
                        (activeTabIndex === 0 &&
                            !isFabricsOrLiningsCategory)) && (
                        <ModalActions>
                            <CancelButton
                                type="button"
                                uiColor="secondary"
                                onClick={onClose}
                                className="rounded-lg mr-auto block shadow-none"
                            >
                                Cancel
                            </CancelButton>

                            <CancelButton
                                type="button"
                                className="rounded-lg block shadow-none"
                                uiColor="secondary"
                                onClick={handleNextChange}
                            >
                                Next
                            </CancelButton>
                        </ModalActions>
                    )}
                </ValidationFormItem>
               {/* <ConfirmationCloseModal
                    uiAcceptLabel="Yes"
                    uiDeclineLabel="No"
                    uiHeader="Confirmation"
                    uiActive={isCloseModalActive}
                    uiOnConfirm={() => {
                        dispatch({ type: "resetFormFields" });
                        setActiveTabIndex(0);
                        onModalClose();
                        if (setSupplierName) setSupplierName("");
                    }}
                    uiOnClose={() => setIsCloseModalActive(false)}
                    uiOnRequestClose={() => setIsCloseModalActive(false)}
                    uiCloseOnOutsideClick={true}
                    uiCloseOnEsc={true}
                >
                    Are you sure you want to exit without saving the changes?
                </ConfirmationCloseModal> */}
            </ModalItem>
        </>
    );
};

export default StandardItemModal;
